import gql from "graphql-tag";

export const CREATE_CLIENT = gql`
  mutation createClientByRecepcionist($data: CreateUserRecepcionistInput!) {
    createClientByRecepcionist(data: $data) {
      id
      name
      email
      phone
      birthday
      avatar
      token
      allow_schedule_app
      fidelity
      address {
        id
        street
        number
        district
        complement
        uf
        city
      }
    }
  }
`;

export const CREATE_WORKER = gql`
  mutation createEmployee($data: CreateUserRecepcionistInput!) {
    createEmployee(data: $data) {
      id
      name
      email
      phone
      birthday
      avatar
      token
      address {
        id
        street
        number
        district
        complement
        uf
        city
      }
      services {
        id
        title
      }
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient($id: ID!, $data: UpdateClientInput!) {
    updateClient(id: $id, data: $data) {
      id
      name
      email
      phone
      birthday
      avatar
      # allow_schedule_app
      token
      address {
        id
        street
        number
        district
        complement
        uf
        city
      }
    }
  }
`;

export const DELETE_CLIENT = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) {
      message
    }
  }
`;

export const CREATE_SCHEDULE = gql`
  mutation createScheduleNoRestriction(
    $schedule_to: String!
    $service_id: Int!
    $customer_id: Int!
    $professional_id: Int!
  ) {
    createScheduleNoRestriction(
      data: {
        schedule_to: $schedule_to
        service_id: $service_id
        customer_id: $customer_id
        professional_id: $professional_id
      }
    ) {
      id
      schedule_to
      status
      service {
        id
        title
        duration
        description
        employees {
          id
          name
          # deletedAt
        }
      }
      app
      customer {
        id
        name
        phone
        email
        avatar
        birthday
        address {
          id
          street
          number
          district
          uf
          city
        }
        role {
          id
          display_name
        }
        # deletedAt
      }
      orders {
        id
        date
        status
        discount
        totalDiscountWithServices
        price_order
        total_price
        deletedAt
        customer {
          id
          name
          fidelity
          # deletedAt
        }
        schedules {
          id
          status
          schedule_to
          service {
            id
            title
            price
            description
          }
          customer {
            id
            name
            # deletedAt
          }
          professional {
            id
            name
            # deletedAt
          }
          discount
        }
        products {
          id
          product_id
          amount
          name
          price
          unit_type
        }
      }
      professional {
        id
        name
        # deletedAt
      }
    }
  }
`;

export const CANCEL_LATE_SCHEDULE = gql`
  mutation cancelLateSchedule($id: ID!) {
    cancelLateSchedule(id: $id) {
      id
      status
      schedule_to
    }
  }
`;

export const UPDATE_SCHEDULE = gql`
  mutation updateSchedule(
    $id: ID!
    $schedule_to: String!
    $professional_id: Int
  ) {
    updateSchedule(
      id: $id
      scheduleTo: $schedule_to
      professionalId: $professional_id
    ) {
      id
      schedule_to
      service {
        id
        title
      }
      customer {
        id
        name
      }
      professional {
        id
        name
      }
    }
  }
`;

export const DELETE_SCHEDULE = gql`
  mutation deleteSchedule($id: ID!) {
    deleteSchedule(id: $id) {
      id
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation deleteOrder($id: ID!) {
    deleteOrder(id: $id) {
      id
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($id: ID!) {
    deleteProduct(id: $id) {
      id
      deletedAt
    }
  }
`;

export const CREATE_WORKER_FUNCTION = gql`
  mutation createService(
    $title: String!
    $duration: Int!
    $price: Float!
    $commission: Float!
    $description: String
    $expiration: Int
  ) {
    createService(
      data: {
        title: $title
        duration: $duration
        commission: $commission
        price: $price
        description: $description
        expiration: $expiration
      }
    ) {
      id
      title
      description
      commission
      price
      duration
    }
  }
`;
export const UPDATE_WORKER_FUNCTION = gql`
  mutation updateService(
    $id: ID!
    $title: String
    $duration: Int
    $price: Float
    $commission: Float
    $description: String
    $expiration: Int
  ) {
    updateService(
      id: $id
      data: {
        title: $title
        duration: $duration
        commission: $commission
        price: $price
        description: $description
        expiration: $expiration
      }
    ) {
      id
      title
      description
      commission
      price
      duration
    }
  }
`;
export const DELETE_WORKER_FUNCTION = gql`
  mutation deleteWorkerFunction($id: ID!) {
    deleteService(id: $id) {
      # id
      # deletedAt
      message
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct(
    $name: String!
    $sell_price: Float!
    $purchase_price: Float!
    $unit_type: String!
    $amount: Int!
    $minimum_amount: Int!
    $consumable: Boolean!
  ) {
    createProduct(
      data: {
        name: $name
        sell_price: $sell_price
        purchase_price: $purchase_price
        unit_type: $unit_type
        amount: $amount
        minimum_amount: $minimum_amount
        consumable: $consumable
      }
    ) {
      id
      name
      sell_price
      purchase_price
      unit_type
      amount
      minimum_amount
      consumable
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $id: ID!
    $name: String!
    $sell_price: Float!
    $purchase_price: Float!
    $unit_type: String!
    $amount: Int!
    $minimum_amount: Int!
    $consumable: Boolean!
  ) {
    updateProduct(
      id: $id
      data: {
        name: $name
        sell_price: $sell_price
        purchase_price: $purchase_price
        unit_type: $unit_type
        amount: $amount
        minimum_amount: $minimum_amount
        consumable: $consumable
      }
    ) {
      id
      name
      sell_price
      purchase_price
      unit_type
      amount
      minimum_amount
      consumable
    }
  }
`;

export const UPDATE_WORKER = gql`
  mutation updateEmployee($id: ID!, $data: UpdateUserInput!) {
    updateEmployee(id: $id, data: $data) {
      id
      name
      email
      phone
      birthday
      avatar
      token
      address {
        id
        street
        number
        district
        complement
        uf
        city
      }
      services {
        id
        title
      }
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder($customerId: ID!) {
    createOrder(customerId: $customerId) {
      id
      customer {
        id
        name
        fidelity
      }
      schedules {
        id
        schedule_to
        service {
          id
          title
          price
          description
        }
        customer {
          id
        }
        professional {
          id
        }
        discount
      }
      date
      products {
        id
        amount
        name
        price
        unit_type
        amount
      }
      status
      total_price
    }
  }
`;

export const UPDATE_ORDER = gql`
  mutation updateOrderStatus(
    $id: ID!
    $status: OrderStatusEnum
    $token: String
    $fidelityManual: Int!
    $discount: Float
  ) {
    updateOrderStatus(
      id: $id
      status: $status
      token: $token
      fidelityManual: $fidelityManual
      discount: $discount
    ) {
      id
      discount
      totalDiscountWithServices
      price_order
      customer {
        id
        name
        fidelity
      }
      schedules {
        id
        schedule_to
        service {
          id
          title
          price
          description
        }
        customer {
          id
          name
        }
        professional {
          id
          name
        }
        discount
      }
      date
      products {
        id
        amount
        name
        price
        unit_type
        amount
      }
      status
      total_price
    }
  }
`;

export const CREATE_CONSUME_HISTORY = gql`
  mutation createProductsConsume(
    $product_id: ID!
    $customer_id: ID!
    $consume_date: String!
    $amount: Int!
  ) {
    createProductsConsume(
      data: {
        product_id: $product_id
        customer_id: $customer_id
        consume_date: $consume_date
        amount: $amount
      }
    ) {
      id
      product {
        id
        name
      }
      customer {
        id
        name
      }
      amount
      consume_date
    }
  }
`;

export const UPDATE_CONSUME_HISTORY = gql`
  mutation updateProductsConsume(
    $id: ID!
    $product_id: ID
    $customer_id: ID
    $amount: Int
    $consume_date: String
  ) {
    updateProductsConsume(
      id: $id
      data: {
        product_id: $product_id
        customer_id: $customer_id
        amount: $amount
        consume_date: $consume_date
      }
    ) {
      id
      product {
        id
        name
      }
      customer {
        id
        name
      }
      amount
      consume_date
    }
  }
`;

export const DELETE_CONSUME_HISTORY = gql`
  mutation deleteProductsConsume($id: ID!) {
    deleteProductsConsume(id: $id) {
      id
      deletedAt
    }
  }
`;

export const ADD_PRODUCT_TO_ORDER = gql`
  mutation addProductsToOrder(
    $id: ID!
    $productId: ID!
    $price: Float!
    $amount: Int!
  ) {
    addProductsToOrder(
      orderId: $id
      productId: $productId
      data: { price: $price, amount: $amount }
    ) {
      id
      discount
      totalDiscountWithServices
      price_order
      status
      date
      updatedAt
      status
      total_price
      customer {
        id
        name
        fidelity
      }
      schedules {
        id
        schedule_to
        service {
          id
          title
          price
          description
        }
        customer {
          id
          name
        }
        professional {
          id
          name
        }
        discount
      }
      date
      products {
        id
        product_id
        amount
        name
        price
        unit_type
        amount
      }
      status
      total_price
    }
  }
`;

export const DELETE_PRODUCT_IN_ORDER = gql`
  mutation deleteProductInOrder($id: ID!) {
    deleteProductInOrder(id: $id) {
      id
      status
      total_price
      date
      discount
      totalDiscountWithServices
      price_order
      customer {
        id
        name
        fidelity
      }
      schedules {
        id
        schedule_to
        service {
          id
          title
          price
          description
        }
        customer {
          id
          name
        }
        professional {
          id
          name
        }
        discount
      }
      products {
        id
        product_id
        amount
        name
        price
        unit_type
        amount
      }
    }
  }
`;

export const ADD_SCHEDULE_TO_ORDER = gql`
  mutation addSchedulesToOrder(
    $id: ID!
    $scheduleId: ID!
    $discount: Float
    $token: String
  ) {
    addSchedulesToOrder(
      orderId: $id
      scheduleId: $scheduleId
      discount: $discount
      token: $token
    ) {
      id
      discount
      totalDiscountWithServices
      price_order
      customer {
        id
        name
        fidelity
      }
      schedules {
        id
        schedule_to
        service {
          id
          title
          price
          description
        }
        customer {
          id
          name
        }
        professional {
          id
          name
        }
        discount
      }
      date
      products {
        id
        amount
        name
        price
        unit_type
        amount
      }
      status
      total_price
    }
  }
`;

export const SET_DISCOUNT_IN_SCHEDULE_IN_ORDER = gql`
  mutation setDiscountInScheduleInOrder(
    $orderId: ID!
    $scheduleId: ID!
    $discount: Float!
    $token: String
  ) {
    setDiscountInScheduleInOrder(
      orderId: $orderId
      scheduleId: $scheduleId
      discount: $discount
      token: $token
    ) {
      id
      discount
      totalDiscountWithServices
      price_order
      customer {
        id
        name
        fidelity
      }
      schedules {
        id
        schedule_to
        service {
          id
          title
          price
          description
        }
        customer {
          id
          name
        }
        professional {
          id
          name
        }
        discount
      }
      date
      products {
        id
        amount
        name
        price
        unit_type
        amount
      }
      status
      total_price
    }
  }
`;

export const DELETE_SCHEDULE_IN_ORDER = gql`
  mutation deleteScheduleInOrder($orderId: ID!, $scheduleId: ID!) {
    deleteScheduleInOrder(orderId: $orderId, scheduleId: $scheduleId) {
      id
      status
      total_price
      date
      discount
      totalDiscountWithServices
      price_order
      customer {
        id
        name
        fidelity
      }
      schedules {
        id
        schedule_to
        service {
          id
          title
          price
          description
        }
        customer {
          id
          name
        }
        professional {
          id
          name
        }
        discount
      }
      products {
        id
        product_id
        amount
        name
        price
        unit_type
        amount
      }
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation createCompany($data: CreateCompanyInput!) {
    createCompany(data: $data) {
      id
      name
      email
      cnpj
      business_name
      address {
        id
        street
        number
        district
        complement
        uf
        city
      }
      phone
      businessHours {
        id
        day
        morning_open
        morning_close
        afternoon_open
        afternoon_close
      }
      maxDaysForScheduling
      commissionCalculationMethod
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($id: ID!, $data: UpdateCompanyInput!) {
    updateCompany(id: $id, data: $data) {
      id
      name
      email
      cnpj
      business_name
      phone
      businessHours {
        id
        day
        morning_open
        morning_close
        afternoon_open
        afternoon_close
      }
      address {
        id
        street
        number
        district
        complement
        uf
        city
      }
      maxDaysForScheduling
      commissionCalculationMethod
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation deleteCompany($id: ID!) {
    deleteCompany(id: $id) {
      id
      deletedAt
    }
  }
`;

export const CREATE_PAYMENT_TYPE = gql`
  mutation createPaymentType($data: CreatePaymentTypeInput!) {
    createPaymentType(data: $data) {
      id
      checked
      type
    }
  }
`;

export const CHECK_PAYMENT_TYPE = gql`
  mutation checkPaymentType($ids: [ID]!) {
    checkPaymentType(ids: $ids) {
      id
      checked
      type
    }
  }
`;

export const UPSERT_MESSAGE = gql`
  mutation upsertMessageSetting($id: ID, $data: UpdateMessageSettingInput!) {
    upsertMessageSetting(id: $id, data: $data)
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($userId: ID!, $id: ID!, $data: UpdateRoleInput!) {
    updateRole(userId: $userId, id: $id, data: $data) {
      id
      name
      display_name
    }
  }
`;

export const CREATE_RESTRICTION = gql`
  mutation createRestriction($data: CreateRestrictionInput!) {
    createRestriction(data: $data) {
      id
    }
  }
`;

export const UPDATE_RESTRICTION = gql`
  mutation updateRestriction($id: ID!, $data: UpdateRestrictionInput!) {
    updateRestriction(id: $id, data: $data) {
      id
    }
  }
`;

export const DELETE_RESTRICTION = gql`
  mutation deleteRestriction($id: ID!) {
    deleteRestriction(id: $id) {
      id
    }
  }
`;

export const CHECK_ADMIN_TO_PROCEED = gql`
  mutation checkAdminToProceed($data: CredentialsInput!) {
    checkAdminToProceed(data: $data) {
      token
    }
  }
`;
export const PAY_ALL_COMMISSIONS = gql`
  mutation payManyDaysExpense($professionalId: ID!) {
    payManyDaysExpense(professionalId: $professionalId) {
      id
      professional {
        name
      }
      price
    }
  }
`;
export const PAY_SINGLE_COMMISSION = gql`
  mutation payOneDayExpense($date: String!, $professionalId: ID!) {
    payOneDayExpense(date: $date, professionalId: $professionalId) {
      id
      professional {
        name
      }
      price
    }
  }
`;

export const RESET_PASSWORD_BY_RECEPCIONIST = gql`
  mutation resetPasswordByRecepcionist($id: ID!, $email: String) {
    resetPasswordByRecepcionist(id: $id, email: $email) {
      id
      name
      email
      phone
      birthday
      avatar
      token
      fidelity
      address {
        id
        street
        number
        district
        complement
        uf
        city
      }
    }
  }
`;

export const SEND_NOTIFICATION = gql`
  mutation sendNotification($data: sendNotification) {
    sendNotification(data: $data)
  }
`;
