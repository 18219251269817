import React, { useEffect } from "react";
import styled, { keyframes, withTheme } from "styled-components";
import Icon from "../Icons/Icon";

const ScaleUp = keyframes`
  from {
    transform: scale(.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;
const ModalContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 70;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.colors.black06};
  transition: opacity 0.3s ease;
  overflow: auto;
  padding: 50px 0;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 12px;
  padding: 35px 50px;
  position: relative;
  z-index: 40;
  margin: 50px auto;
  box-shadow: 1px 3px 10px grey;
  animation: ${ScaleUp} .4s;
  color: ${props => props.theme.colors.fontPrimary};
  ${({ extraLarge }) =>
    extraLarge &&
    `
    min-width: 1055px;
  `}
  ${({ large }) =>
    large &&
    `
      min-width: 600px;
      max-width: 800px;
  `}
  ${({ medium }) =>
    medium &&
    `
      min-width: 400px;
      max-width: 600px;
  `}
`;

export const CloseModalStyle = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 1.115em;
  color: ${props => props.theme.colors.primary};
  z-index: 65;
`;

export const CloseModal = props => (
  <CloseModalStyle {...props}>
    <Icon color={props.color} name="close" backgroundColor="white" />
  </CloseModalStyle>
);

function Modal({
  theme,
  onClose = () => {},
  extraLarge,
  large,
  medium,
  children,
  ...props
}) {
  useEffect(() => {
    const keyUpEvent = e => {
      if (e.key === "Escape") {
        onClose();
      }
    };
    document.body.style.overflow = "hidden";
    window.addEventListener("keyup", keyUpEvent);
    return () => {
      window.removeEventListener("keyup", keyUpEvent);
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <ModalContainer {...props}>
      <ModalContent large={large} medium={medium} extraLarge={extraLarge}>
        <CloseModal color={theme.colors.primary} onClick={onClose} />
        {children}
      </ModalContent>
    </ModalContainer>
  );
}

export default withTheme(Modal);
