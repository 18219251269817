import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
const INITIAL_STATE = Immutable({
  orders: [],
  pages: 1,
  page: 1,
  refetch: false,
  edit: {
    id: "",
    customer: {},
    schedules: {},
    date: "",
    orders: {},
    status: "",
    total_price: "",
  },
  new: {
    id: "",
    customer: {},
    schedules: {},
    date: "",
    orders: {},
    status: "",
    total_price: "",
  },
});

export const { Types, Creators: OrderActions } = createActions({
  updateOrders: ["orders", "pages"],
  updatePage: ["page"],
  updateOrderEdit: ["order"],
  updateOrderNew: ["order"],
  resetOrderNew: [""],
  resetOrderEdit: [""],
  refetchOrders: [""],
});

const updateOrders = (state = INITIAL_STATE, { orders, pages }) =>
  state.merge({ orders, pages }, { deep: true });
const updatePage = (state = INITIAL_STATE, { page }) =>
  state.merge({ page }, { deep: true });
const updateEdit = (state = INITIAL_STATE, { order }) =>
  state.merge({ edit: order }, { deep: true });
const updateNew = (state = INITIAL_STATE, { order }) =>
  state.merge({ new: order }, { deep: true });

export default createReducer(INITIAL_STATE, {
  [Types.UPDATE_ORDERS]: updateOrders,
  [Types.UPDATE_PAGE]: updatePage,
  [Types.UPDATE_ORDER_EDIT]: updateEdit,
  [Types.UPDATE_ORDER_NEW]: updateNew,
  [Types.RESET_ORDER_NEW]: (state = INITIAL_STATE) =>
    state.merge({ new: INITIAL_STATE.new }, { deep: true }),
  [Types.RESET_ORDER_EDIT]: (state = INITIAL_STATE) =>
    state.merge({ edit: INITIAL_STATE.edit }, { deep: true }),
  [Types.REFETCH_ORDERS]: (state = INITIAL_STATE) =>
    state.merge({ refetch: !state.refetch }, { deep: true }),
});
