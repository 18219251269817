import React from "react";
import PropTypes from "prop-types";
import ReactSVG from "react-svg";
import styled, { withTheme } from "styled-components";

const StyledSVG = styled(ReactSVG)`
  > * {
    fill: ${({ color }) => color};
  }
`;

/**
 * Component de Icon
 */
function Icon({
  size = "normal",
  color,
  name,
  backgroundColor,
  display = "block",
  theme,
  ...props
}) {
  if (!color) color = theme.colors.primary;
  return (
    <span
      style={{ height: Icon.sizes[size], width: Icon.sizes[size], display }}
      {...props}
    >
      <StyledSVG
        svgStyle={{ width: "100%", height: "100%", backgroundColor }}
        color={color}
        src={"/icons/" + icons[name]}
      />
    </span>
  );
}

const icons = {
  birthday_cake:"birthday_cake.svg",
  clean_list:"clean_list.svg",
  select_all:"select_all.svg",
  logout: "logout.svg",
  info: "info.svg",
  helper: "helper.svg",
  calendar: "calendar.svg",
  key: "key.svg",
  arrowDown: "arrow-down.svg",
  "outline-add_circle_outline": "outline-add_circle_outline-24px.svg",
  "outline-all_inbox": "outline-all_inbox-24px.svg",
  "outline-arrow_back_ios": "outline-arrow_back_ios-24px.svg",
  "outline-arrow_back": "outline-arrow_back-24px.svg",
  "outline-arrow_forward_ios": "outline-arrow_forward_ios-24px.svg",
  "outline-attach_money": "outline-attach_money-24px.svg",
  "outline-cake": "outline-cake-24px.svg",
  "outline-calendar_today": "outline-calendar_today-24px.svg",
  "outline-check_box_outline_blank": "outline-check_box_outline_blank-24px.svg",
  "outline-check_box": "outline-check_box-24px.svg",
  "outline-check": "outline-check-24px.svg",
  "outline-contact_mail": "outline-contact_mail-24px.svg",
  "outline-contact_phone": "outline-contact_phone-24px.svg",
  "outline-dashboard": "outline-dashboard-24px.svg",
  "outline-edit": "outline-edit-24px.svg",
  "outline-mail": "outline-mail-24px.svg",
  "outline-more_horiz": "outline-more_horiz-24px.svg",
  "outline-notifications_active": "outline-notifications_active-24px.svg",
  "outline-notifications": "outline-notifications-24px.svg",
  "outline-people_outline": "outline-people_outline-24px.svg",
  "outline-radio_button_checked": "outline-radio_button_checked-24px.svg",
  "outline-radio_button_unchecked": "outline-radio_button_unchecked-24px.svg",
  "outline-sentiment_dissatisfied": "outline-sentiment_dissatisfied-24px.svg",
  "outline-sentiment_satisfied": "outline-sentiment_satisfied-24px.svg",
  "outline-star_border": "outline-star_border-24px.svg",
  "outline-star": "outline-star-24px.svg",
  "outline-thumb_down_alt": "outline-thumb_down_alt-24px.svg",
  "outline-thumb_up_alt": "outline-thumb_up_alt-24px.svg",
  "outline-toggle_off": "outline-toggle_off-24px.svg",
  "outline-toggle_on": "outline-toggle_on-24px.svg",
  "outline-view_headline": "outline-view_headline-24px.svg",
  settings: "settings.svg",
  "man-user": "man-user.svg",
  "open-scissors": "open-scissors.svg",
  "chrome-reader": "outline-chrome_reader_mode-24px.svg",
  "vertical-align": "outline-vertical_align_bottom-24px.svg",
  remove: "remove.svg",
  "outline-home": "outline-home-24px.svg",
  close: "close.svg",
  "next-arrow": "next-arrow.svg",
  "last-arrow": "last-arrow.svg",
  clients: "clients.svg",
  order: "order.svg",
  restriction: "restriction.svg",
  schedule: "calendar.svg",
  cashier: "cashier.svg",
  percent: "comission.svg",
  statistics: "outline-dashboard-24px.svg",
  products: "outline-all_inbox-24px.svg",
  bell: "bell.svg",
  user: "user.svg",
  permission: "permission.svg",
  "menu-card": "menu-card.svg",
  "fidelity-card": "fidelity-card.svg",
  "paper-plane": "paper-plane.svg",
  "remove-client-notification": "remove-client-notification.svg",
};

Icon.propTypes = {
  /**
   * Icon label.
   */
  children: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(["small", "normal", "large", "xlarge"]),
  name: PropTypes.oneOf(Object.keys(icons)),
};

Icon.sizes = {
  small: "12px",
  regular: "15px",
  normal: "20px",
  medium: "25px",
  large: "32px",
  xlarge: "38px",
  xxlarge: "88px",
  verylarge: "120px",
};

export default withTheme(Icon);
