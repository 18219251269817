import { combineReducers } from "redux";

import users from "./users";
import workers from "./workers";
import workerFunctions from "./workerFunctions";
import products from "./products";
import schedule from "./schedule";
import orders from "./orders";
import workJourney from "./workJourney";

export default combineReducers({
  users,
  workers,
  workerFunctions,
  products,
  schedule,
  orders,
  workJourney,
});
