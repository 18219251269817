import { useState } from "react";

function useAuth() {
  const [isAuth, toggleAuth] = useState(!!window.localStorage.getItem("token"));

  return {
    isAuth,
    toggleAuth,
    token: window.localStorage.getItem("token"),
    user: isAuth && JSON.parse(window.localStorage.getItem("user")),
  };
}

export default useAuth;
