import React from "react";
import { withTheme } from "styled-components";

function User({ scale = 0.6, theme }) {
  return (
    <div>
      <svg
        style={{ transform: `scale(${scale})` }}
        width="69"
        height="69"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <title>background</title>
          <rect
            fill="#ffffff"
            id="canvas_background"
            height="71"
            width="71"
            y="-1"
            x="-1"
          />
          <g
            display="none"
            overflow="visible"
            y="0"
            x="0"
            height="100%"
            width="100%"
            id="canvasGrid"
          >
            <rect
              fill="url(#gridpattern)"
              strokeWidth="0"
              y="0"
              x="0"
              height="100%"
              width="100%"
            />
          </g>
        </g>
        <g>
          <title>Layer 1</title>
          <defs>
            <linearGradient id="svg_13" x1="0" x2="1" y1="0.5" y2="0.5">
              <stop
                offset="0"
                stopColor={theme.colors.primary}
                stopOpacity="0.996094"
              />
              <stop
                offset="1"
                stopColor={theme.colors.primary}
                stopOpacity="0.5"
              />
            </linearGradient>
          </defs>
          <g stroke="null" id="svg_21">
            <g
              stroke="null"
              transform="matrix(1.92419762922881,0,0,1.92419762922881,-10028.411597988672,-6972.548575995082) "
              id="svg_20"
            >
              <title
                stroke="null"
                transform="translate(0,0.433086097240448) translate(0,0.433086097240448) translate(0,0.433086097240448) translate(0,-0.433086097240448) translate(0,-0.433086097240448) translate(0,-0.433086097240448) translate(0,-0.433086097240448) translate(0,-0.433086097240448) translate(0,-0.433086097240448) translate(0,-0.433086097240448) translate(0,-0.433086097240448) translate(0,0.433086097240448) translate(0,0.433086097240448) translate(0,0.433086097240448) translate(0.433086097240448,-4.547473508864641e-13) translate(-0.433086097240448,-4.547473508864641e-13) translate(-0.433086097240448,-4.547473508864641e-13) translate(-0.433086097240448,-4.547473508864641e-13) translate(-0.433086097240448,-4.547473508864641e-13) translate(-42.09546661376953,-29.62273597717285) translate(-214.6210174560547,-111.93153381347656) translate(-1.0268948078155518,0) translate(5197.52490234375,0) translate(0,3584.5) "
              >
                Layer 1
              </title>
              <linearGradient
                stroke="null"
                transform="translate(0,0.433086097240448) translate(0,0.433086097240448) translate(0,0.433086097240448) translate(0,-0.433086097240448) translate(0,-0.433086097240448) translate(0,-0.433086097240448) translate(0,-0.433086097240448) translate(0,-0.433086097240448) translate(0,-0.433086097240448) translate(0,-0.433086097240448) translate(0,-0.433086097240448) translate(0,0.433086097240448) translate(0,0.433086097240448) translate(0,0.433086097240448) translate(0.433086097240448,-4.547473508864641e-13) translate(-0.433086097240448,-4.547473508864641e-13) translate(-0.433086097240448,-4.547473508864641e-13) translate(-0.433086097240448,-4.547473508864641e-13) translate(-0.433086097240448,-4.547473508864641e-13) translate(-42.09546661376953,-29.62273597717285) translate(-214.6210174560547,-111.93153381347656) translate(-1.0268948078155518,0) translate(5197.52490234375,0) translate(0,3584.5) "
                id="svg_14"
                x1="0"
                x2="1"
                y1="0.5"
                y2="0.5"
              >
                <stop stroke="null" offset="0" stopColor="#00f38d" />
                <stop stroke="null" offset="1" stopColor="#009eff" />
              </linearGradient>
              <g stroke="null" id="svg_10">
                <path
                  stroke="null"
                  d="m5246.661685,3641.539008c0,9.5884 -7.77271,17.36111 -17.36111,17.36111s-17.36111,-7.77271 -17.36111,-17.36111s7.77271,-17.36111 17.36111,-17.36111s17.36111,7.77271 17.36111,17.36111zm0,0"
                  fill="url(#svg_13)"
                  id="svg_12"
                />
                <path
                  stroke="null"
                  d="m5232.969955,3640.992238c1.59475,-1.2011 2.6279,-3.10951 2.6279,-5.25501c0,-3.62529 -2.9495,-6.57479 -6.57505,-6.57479c-3.62529,0 -6.57479,2.94951 -6.57479,6.57479c0,2.1455 1.03288,4.05391 2.62763,5.25501c-4.26981,1.6027 -7.31706,5.72682 -7.31706,10.54976l2.0345,0c0,-5.08944 4.14054,-9.22972 9.22998,-9.22972c5.08918,0 9.22971,4.14028 9.22971,9.22972l2.03451,0c-0.00026,-4.82295 -3.04725,-8.94706 -7.31733,-10.54976zm-8.48744,-5.25501c0,-2.50366 2.03663,-4.54028 4.54029,-4.54028s4.54028,2.03662 4.54028,4.54028s-2.03662,4.54055 -4.54028,4.54055s-4.54029,-2.03689 -4.54029,-4.54055zm0,0"
                  fill="#fff"
                  id="svg_11"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default withTheme(User);
