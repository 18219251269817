// Default theme values
const themeValues = {
  primaryColor: "#119999",
  secondaryColor: "#111149",
  fontPrimaryColor: "#888888",
  fontSecondaryColor: "#111111",
  fontTertiaryColor: "#FFFFFF",
  gradientSidebar: "#111111",
  gradientSidebarLeft: "#119999",
  fontSidebar: "#199999",
  loginColor: "#000000",
  barberLogo: "/imgs/duque.png",
  backgroundLogin: "/imgs/login-background.jpg",
};

export function setThemeConfig(config) {
  Object.keys(config).forEach(value => {
    const valueLowerCase =
      value.substring(0, 1).toLowerCase() + value.substring(1);
    console.log("valueLowerCase", valueLowerCase);
    themeValues[valueLowerCase] = config[value];
  });
}

export default function configTheme() {
  return themeValues;
}
