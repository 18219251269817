import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import UserPhoto from "../Theme/UserPhoto";
import Notification from "../Notification/Notification";
import StyledDropdownMenu from "../General/StyledDropdownMenu";
import ChangePasswordModal from "../General/ChangePasswordModal";
import Icon from "../Icons/Icon";

const HeaderContainer = styled.header`
  grid-area: header;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  z-index: 60;
  box-shadow: 1px 1px 16px 0px ${props => props.theme.colors.black02};
`;

const HeaderMenu = styled.div`
  width: auto;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Name = styled.span`
  font-size: 0.9em;
  color: ${props => props.theme.colors.fontPrimary};
  margin-right: 10px;
  &:hover {
    cursor: pointer;
  }
`;

function Header({ theme }) {
  const user = JSON.parse(window.localStorage.getItem("user"));
  const [
    isShowingChangePasswordModal,
    setIsShowingChangePasswordModal,
  ] = useState(false);

  // useEffect(() => {
  //   // instantiateStyles()
  // }, [])

  return (
    user && (
      <HeaderContainer>
        <HeaderMenu>
          <Notification />
          <UserPhoto />
          <div>
            <StyledDropdownMenu
              showOnHover={true}
              items={[
                {
                  text: "Alterar Senha",
                  onClick: () => setIsShowingChangePasswordModal(true),
                },
              ]}
            >
              <div style={{ display: "flex" }}>
                <Name>{user.name}</Name>
                <div style={{ marginTop: "2px", marginRight: "15px" }}>
                  <Icon
                    name="arrowDown"
                    size={"small"}
                    color={theme.colors.fontPrimary}
                  />
                </div>
              </div>
            </StyledDropdownMenu>
            {/* <Arrow /> */}
          </div>
        </HeaderMenu>
        {isShowingChangePasswordModal && (
          <ChangePasswordModal
            onClose={() => setIsShowingChangePasswordModal(false)}
          ></ChangePasswordModal>
        )}
      </HeaderContainer>
    )
  );
}

export default withTheme(Header);
