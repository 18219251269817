import React, { Suspense } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import routes, { RouteWithSubRoutes } from "./routes";
import ApolloProvider from "./config/ApolloProvider";
import useAuth from "./hooks/useAuth";
import { Provider } from "react-redux";
import store from "./store";
import { Container, Row } from "./components/General/StyledItems";
import Sidebar from "./components/FixedComponents/Sidebar";
import Header from "./components/FixedComponents/Header";
import { withTheme } from "styled-components";

require("dotenv").config();

function App({ theme }) {
  const { isAuth } = useAuth();
  return (
    <ApolloProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Suspense
            fallback={
              window.location.pathname === "/" ? (
                <Row
                  column
                  height="100vh"
                  width="100%"
                  style={{ backgroundColor: theme.colors.primary }}
                ></Row>
              ) : (
                <Container>
                  <Sidebar /> <Header />
                </Container>
              )
            }
          >
            <Switch>
              {routes.publicRoutes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}

              {isAuth ? (
                <Container>
                  <Sidebar />
                  <Header />
                  {routes.secretRoutes.map((route, i) => (
                    <RouteWithSubRoutes key={i} {...route} />
                  ))}
                </Container>
              ) : (
                <Redirect to="/login" />
              )}
            </Switch>
          </Suspense>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  );
}

export default withTheme(App);
