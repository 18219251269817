import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  /* overflow: hidden; */
  grid-template-columns: min-content 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-areas: "sidebar header" "sidebar content";

  ${({ center }) =>
    center &&
    `
       grid-template-columns: 1fr;
    `}

  > header ~ * {
    padding: 0 5vw 30px 5vw;
  }
`;

export const Status = styled.a`
  margin: 0 10px;
  font-size: 0.8em;
  ${props =>
    props.active
      ? `
        color: ${props => props.theme.colors.statusActive};
      `
      : `
        color: ${props => props.theme.colors.statusNotActive};
      `}
`;

export const CenteredContainer = styled(Container)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${({ marginTop }) => marginTop};
    margin-left: ${({ marginLeft }) => marginLeft};
    margin-bottom: ${({ marginBottom }) => marginBottom};
    margin-right: ${({ marginRight }) => marginRight};
    width: ${({ width = "100%" }) => width};
    height: ${({ height }) => height};
    ${({ between }) =>
      between &&
      `
      justify-content: space-between;
    `}
    ${({ around }) =>
      around &&
      `
      justify-content: space-around;
    `}
    ${({ center }) =>
      center &&
      `
      align-items: center;
    `}
    ${({ start }) =>
      start &&
      `
      align-items: flex-start;
    `}
    ${({ end }) =>
      end &&
      `
      align-items: flex-end;
    `}
    ${({ column }) =>
      column &&
      `
      flex-direction: column;
    `}
    ${({ justifyCenter }) =>
      justifyCenter &&
      `
      justify-content: center;
    `}
    ${({ backgroundColor }) =>
      backgroundColor &&
      `
      background-color: ${backgroundColor};
    `}
    ${({ padding }) =>
      padding &&
      `
      padding: ${padding};
    `}
`;

export const Grid = styled.div`
    display: grid;
    height: 100%;
    grid-template-columns: ${({ col }) =>
      col ? `repeat(${col}, 1fr)` : "repeat(1, 1fr)"};
    ${({ colTemplate }) =>
      colTemplate &&
      `
      grid-template-columns: ${colTemplate};
    `}

    grid-column-gap: 15px;
    place-items: flex-end;

    ${({ marginTop }) =>
      marginTop &&
      `
      margin-top: ${marginTop};
    `}
    ${({ center }) =>
      center &&
      `
      place-items: center;
    `}
     ${({ start }) =>
       start &&
       `
      place-items: start;
    `}
`;

export const Square = styled.div`
  display: block;
  width: 15px;
  height: 15px;
  color: ${({ color }) => color};
  background-color: ${({ color }) => color};
  ${({ rounded }) =>
    rounded &&
    `
    border-radius: 50%;
    width: 10px;
    height: 10px;

  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  margin-right: ${({ marginRight }) => marginRight};
`;
export const HeightBetween = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled.h1`
  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color = props => props.theme.colors.primary }) => color};
  text-align: ${({ textAlign }) => textAlign};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  ${({ center }) =>
    center &&
    `
        text-align: center;
    `}
`;

StyledTitle.defaultProps = {
  fontSize: "1.25em",
};

export const SmallText = styled.span`
  font-size: ${({ fontSize }) => fontSize || `.9em`};
  color: ${({ color }) => color};
  margin-top: ${({ marginTop }) => marginTop};
  margin-right: ${({ marginRight }) => marginRight};
  width: ${({ width }) => width};
  flex: ${({ flex }) => flex};
`;

export const MediumText = styled.span`
  font-size: 1em;
  color: ${({ color }) => color};
  margin-top: ${({ marginTop }) => marginTop};
  margin-left: ${({ marginLeft }) => marginLeft};
  ${({ bold }) =>
    bold &&
    `
        font-weight: bold;
    `}
`;

export const CustomText = styled.span`
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
  margin-top: ${({ marginTop }) => marginTop};
`;

export const OrDiv = styled.div`
  align-items: center;
  display: flex;
  color: ${props => props.theme.colors.primary05};
  margin: 10px 0;
  white-space: nowrap;
  font-weight: bold;
  &:before,
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: ${props => props.theme.colors.primary05};
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin: 10px 0;
  color: ${props => props.theme.colors.fontPrimary};
  font-size: 0.9em;
  color: ${props => props.theme.colors.fontPrimary};
  margin-top: ${({ marginTop }) => marginTop};
  ${Square} {
    border: 2px solid currentColor;
    border-radius: 2px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 150ms;
    &:before {
      content: "✔";
      font-size: 0.7em;
      transform: scale(0.3);
      transition: opacity 150ms, transform 150ms;
      will-change: transform;
      opacity: 0;
    }
  }

  input:checked ~ ${Square} {
    color: ${props => props.theme.colors.lightGreen};
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export const StatusIndicator = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background: ${({ status }) =>
    status === "ABERTO"
      ? props => props.theme.colors.statusOrderOpen
      : props => props.theme.colors.statusOrderClose};
  border-radius: 50%;
  align-self: center;
`;
