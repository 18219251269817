export const WS_API_URL =
  process.env.REACT_APP_WS_API_URL ||
  "wss://homolog-barbeariaduque.brainny.cc:4000";
export const API_URL =
  process.env.REACT_APP_API_URL ||
  "https://homolog-barbeariaduque.brainny.cc:4000";
export const CONFIG_API_URL =
  process.env.REACT_APP_CONFIG_API_URL ||
  "https://oniadm.brainny.cc/company-instances/1";

export const STATUS_ACTIONS = {
  ABERTO: {
    changeTo: "FECHADO",
    buttonText: "Encerrar comanda",
    messageConfirm: "Deseja encerrar esta comanda?",
    messageSuccess: "Comanda fechada",
    messageError: "Ocorreu um erro ao fechar a comanda",
    messageFidelity:
      "Deseja realmente adicionar mais de um ponto de fidelidade para este cliente?",
    messageFidelityDiscount:
      "Deseja realmente adicionar mais de um ponto de fidelidade para este cliente e adicionar desconto nesta comanda?",
    messageDiscount: "Deseja realmente dar desconto para esta comanda?",
    messageWithoutDiscount:
      "Este cliente possui 10 pontos fidelidade. Realmente deseja não dar desconto nesta comanda?",
  },
  REABERTO: {
    changeTo: "FECHADO",
    buttonText: "Encerrar comanda",
    messageConfirm: "Deseja encerrar esta comanda?",
    messageSuccess: "Comanda fechada",
    messageError: "Ocorreu um erro ao fechar a comanda",
    messageFidelity:
      "Deseja realmente adicionar mais de um ponto de fidelidade para este cliente?",
    messageFidelityDiscount:
      "Deseja realmente adicionar mais de um ponto de fidelidade para este cliente e adicionar desconto nesta comanda?",
    messageDiscount: "Deseja realmente dar desconto para esta comanda?",
    messageWithoutDiscount:
      "Este cliente possui 10 pontos fidelidade. Realmente deseja não dar desconto nesta comanda?",
  },
  FECHADO: {
    changeTo: "ABERTO",
    changeToRe: "REABERTO",
    buttonText: "Reabrir comanda",
    messageConfirm: "Deseja abrir esta comanda?",
    messageSuccess: "Comanda aberta",
    messageError:
      "Não foi possível abrir esta comanda. Contate um administrador.",
  },
};

export const ROLE_ADMIN = "admin";

export const ComissionCalculationMethodEnum = Object.freeze({
  WITHOUT_DISCOUNT: "sem desconto",
  WITH_DISCOUNT: "com desconto",
});
