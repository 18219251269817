import gql from "graphql-tag";

export const NEW_SCHEDULE_SUBSCRIPTION = gql`
  subscription newSchedule {
    newSchedule {
      id
      schedule_to
      status
      service {
        id
        title
        duration
        description
        employees {
          id
          name
          # deletedAt
        }
      }
      app
      customer {
        id
        name
        phone
        email
        avatar
        birthday
        address {
          id
          street
          number
          district
          uf
          city
        }
        role {
          id
          display_name
        }
        # deletedAt
      }
      orders {
        id
        date
        status
        discount
        totalDiscountWithServices
        price_order
        total_price
        deletedAt
        customer {
          id
          name
          fidelity
          # deletedAt
        }
        schedules {
          id
          status
          schedule_to
          service {
            id
            title
            price
            description
          }
          customer {
            id
            name
            # deletedAt
          }
          professional {
            id
            name
            # deletedAt
          }
          discount
        }
        products {
          id
          product_id
          amount
          name
          price
          unit_type
        }
      }
      professional {
        id
        name
        # deletedAt
      }
    }
  }
`;
