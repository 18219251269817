import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
const INITIAL_STATE = Immutable({
  schedules: [],
  schedulesDay: [],
  edit: {
    schedule_to: {
      time: "",
      date: "",
    },
    service_id: "",
    customer_id: "",
    professional_id: "",
  },
  view: {
    service: {
      employees: [],
    },
    employee: {},
    times: [],
    employeeTimes: [],
    selectedTime: "",
    user: {},
  },
  new: {
    schedule_to: {
      time: "",
      date: "",
    },
    service_id: "",
    customer_id: "",
    professional_id: "",
  },
});

export const { Types, Creators: ScheduleActions } = createActions({
  updateSchedules: ["schedules"],
  updateSchedulesDay: ["schedules"],
  updateScheduleEdit: ["schedule"],
  updateScheduleNew: ["schedule"],
  updateScheduleView: ["props"],
  resetScheduleNew: [""],
  resetScheduleEdit: [""],
  resetScheduleView: [""],
});

export default createReducer(INITIAL_STATE, {
  [Types.UPDATE_SCHEDULES]: (state = INITIAL_STATE, { schedules }) =>
    state.merge({ schedules }, { deep: true }),
  [Types.UPDATE_SCHEDULES_DAY]: (state = INITIAL_STATE, { schedules }) =>
    state.merge({ schedulesDay: schedules }, { deep: true }),
  [Types.UPDATE_SCHEDULE_EDIT]: (state = INITIAL_STATE, { schedule }) =>
    state.merge({ edit: schedule }, { deep: true }),
  [Types.UPDATE_SCHEDULE_NEW]: (state = INITIAL_STATE, { schedule }) =>
    state.merge({ new: schedule }, { deep: true }),
  [Types.UPDATE_SCHEDULE_VIEW]: (state = INITIAL_STATE, { props }) =>
    state.merge({ view: props }, { deep: true }),
  [Types.RESET_SCHEDULE_NEW]:
    // usado um novo immutable como retorno pois o merge não estava resetando campos que não continham no estado inicial, fazendo o reset não funcionar no modal de novo agendamento
    (state = INITIAL_STATE) =>
      Immutable({ ...state, new: INITIAL_STATE.new, view: INITIAL_STATE.view }),
  [Types.RESET_SCHEDULE_EDIT]: (state = INITIAL_STATE) =>
    state.merge({ edit: INITIAL_STATE.edit }, { deep: true }),
  [Types.RESET_SCHEDULE_VIEW]: (state = INITIAL_STATE) =>
    state.merge({ view: INITIAL_STATE.view }, { deep: true }),
});
