import configTheme from "../../config/theme";

/// //////////////////////////////////////////////////////////////////////////////

// DO NOT USE THIS COLORS DIRECTLY. GET COLORS BY STYLED THEME TO USE DYNAMICALLY.

/// //////////////////////////////////////////////////////////////////////////////

export default function colors() {
  return {
    primary: configTheme().primaryColor,
    get primary09() {
      return hexToRGBA(this.primary, 0.9);
    },
    get primary07() {
      return hexToRGBA(this.primary, 0.7);
    },
    get primary05() {
      return hexToRGBA(this.primary, 0.5);
    },
    get primary03() {
      return hexToRGBA(this.primary, 0.3);
    },
    get darkPrimary() {
      return hexToRGBA(this.primary, 1, 0.5);
    },
    secondary: configTheme().secondaryColor,
    fontPrimary: configTheme().fontPrimaryColor,
    get fontPrimary08() {
      return hexToRGBA(this.fontPrimary, 0.8);
    },
    get fontPrimary03() {
      return hexToRGBA(this.fontPrimary, 0.3);
    },
    get fontPrimary01() {
      return hexToRGBA(this.fontPrimary, 0.1);
    },
    fontSecondary: configTheme().fontSecondaryColor,
    fontTertiary: configTheme().fontTertiaryColor,
    gradientSidebar: configTheme().gradientSidebar,
    gradientSidebarLeft: configTheme().gradientSidebarLeft,
    fontSidebar: configTheme().fontSidebar,
    error: "#E5493A",
    get error02() {
      return hexToRGBA(this.error, 0.2);
    },
    get borderError() {
      return hexToRGBA(this.error, 0.4);
    },
    cancel: "#DD3333",
    disable: "#E8E8E8",
    login: configTheme().loginColor,
    get shadowLogin() {
      return hexToRGBA(this.login, 0.7, 1.2);
    },
    get backgroundLogin() {
      return hexToRGBA(this.login, 0.7);
    },
    get tabItem() {
      return this.primary;
    },
    get tabItem() {
      return this.primary;
    },
    kanbanCell: "#FFFFFF",
    subRow: "#FAFAFA",
    inputFile: "#EEEEEE",
    discountTime: "#00814B",
    cashIn: "#00814B",
    cashOut: "#DD3E3E",
    cashProfit: "#226699",
    statusInitial: "#D6C809",
    statusOpen: "#226699",
    statusProgress: "#C4A13E",
    statusClosed: "#00814B",
    statusCanceledByDelay: "#DD3E3E",
    statusRestriction: "#999999",
    statusActive: "#00814B",
    statusNotActive: "#DD3E3E",
    statusOrderOpen: "#9FC508",
    statusOrderClose: "#E77878",
    errorColor:"#FFCCCC",
    lightGreen: "#74AD31",
    white: "#FFFFFF",
    get white08() {
      return hexToRGBA(this.white, 0.8);
    },
    get white03() {
      return hexToRGBA(this.white, 0.3);
    },
    whiteTwo: "#F1F1F1",
    black: "#000000",
    get black06() {
      return hexToRGBA(this.black, 0.6);
    },
    get black04() {
      return hexToRGBA(this.black, 0.4);
    },
    get black02() {
      return hexToRGBA(this.black, 0.2);
    },
    gray: "#C4C4C4",
    get lightGray() {
      return hexToRGBA(this.gray, 1, 1.1);
    },
  };
}

function hexToRGBA(hex, alpha = 1, brightness = 1) {
  const r = Math.floor(parseInt(hex.slice(1, 3), 16) * brightness);
  const g = Math.floor(parseInt(hex.slice(3, 5), 16) * brightness);
  const b = Math.floor(parseInt(hex.slice(5, 7), 16) * brightness);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
