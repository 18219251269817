import React from "react";
import * as yup from "yup";
import Modal from "../UIComponents/Modal";
import { useApolloClient } from "react-apollo-hooks";
import { ModalColumn, FormButtonContainer } from "./StyledForm";
import { StyledTitle } from "./StyledItems";
import { ButtonSave } from "../Products/ProductsControl/ProductControl.styles";
import { UPDATE_WORKER } from "../../graphql/mutations";
import useToast from "../../hooks/useToast";
import { withTheme } from "styled-components";
import { withFormik } from "formik";
import { FieldWithError } from "./FieldWithError";

function ChangePasswordModal({
  onClose,
  theme,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  isValid,
}) {
  const client = useApolloClient();
  const { Toast } = useToast();
  const currentUserId = JSON.parse(localStorage.getItem("user")).id;

  async function onSubmit(e) {
    e.preventDefault();
    console.log({ errors, values });
    try {
      await client.mutate({
        mutation: UPDATE_WORKER,
        variables: {
          id: currentUserId,
          data: {
            password: values.password,
          },
        },
      });

      Toast.fire({
        title: "Senha alterada com sucesso.",
        type: "success",
      });
    } catch (error) {
      Toast.fire({
        title: error.message.split("error:")[1],
        type: "error",
      });
    } finally {
      onClose();
    }
  }
  return (
    <Modal medium onClose={onClose}>
      <form onSubmit={onSubmit}>
        <StyledTitle color={theme.colors.primary} marginTop={"1em"} center>
          Alterar Senha
        </StyledTitle>
        <ModalColumn>
          <FieldWithError
            name="password"
            label="Nova senha"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={errors.password}
            touched={touched.password}
            required
            type={"password"}
          />
          {/* <ModalInput
            placeholder=" "
            name="password"
            type="password"
            value={ password } onChange={ e => setPassword(e.target.value) }
            required
          /> */}
        </ModalColumn>
        <ModalColumn>
          <FieldWithError
            name="passwordConfirmation"
            label="Confirmar senha"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordConfirmation}
            error={errors.passwordConfirmation}
            touched={touched.passwordConfirmation}
            required
            type={"password"}
          />
        </ModalColumn>
        <ModalColumn>
          <FormButtonContainer disabled={!isValid}>
            <ButtonSave type="submit">Salvar</ButtonSave>
          </FormButtonContainer>
        </ModalColumn>
      </form>
    </Modal>
  );
}

const schema = yup
  .object()
  .shape({
    password: yup
      .string()
      .required("Campo obrigatório")
      .min(6, "A senha deve possuir pelo menos 6 characteres"),
    passwordConfirmation: yup
      .string()
      .required("Campo obrigatório")
      .oneOf([yup.ref("password"), null], "As senhas não conferem"),
  })
  .noUnknown();

export default withTheme(
  withFormik({
    validationSchema: schema,
    validateOnBlur: false,
    validateOnChange: true,
  })(ChangePasswordModal)
);
