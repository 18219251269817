import React, { Component } from "react";
import styled, { css, withTheme } from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { Column, Row, HeightBetween } from "../General/StyledItems";
import Icon from "../Icons/Icon";
import configTheme from "../../config/theme";

const Logo = styled.img`
  height: 68px;
  object-fit: contain;
  padding: 7px;
`;

const SidebarContainer = styled.aside`
  height: 100%;
  width: 100px;
  position: relative;
  grid-area: sidebar;
`;

const SidebarContainerFixed = styled.div`
  position: fixed;
  background: linear-gradient(
    to left,
    ${props => props.theme.colors.gradientSidebar},
    ${props => props.theme.colors.gradientSidebarLeft}
  );
  display: inline-flex;
  flex-direction: column;
  width: 100px;
  height: 100%;
  z-index: 10;
`;

const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${({ margin }) => margin};
  color: ${props => props.theme.colors.fontSidebar};
  transition: 0.3s;
  filter: brightness(0.7);

  ${({ active }) =>
    active &&
    css`
      filter: brightness(2);
      svg {
        fill: ${props => props.theme.colors.fontSidebar};
      }
      ${TextIcon} {
        font-weight: bold;
      }
    `}
  &:hover {
    filter: brightness(0.9);

    svg {
      filter: brightness(2);
      fill: ${props => props.theme.colors.fontSidebar};
    }

    span {
      filter: brightness(2);
      color: ${props => props.theme.colors.fontSidebar};
    }
  }
`;

const TextIcon = styled.span`
  font-size: 0.75em;
  font-family: sans-serif;
  line-height: 20px;
  font-family: Montserrat;
  margin-top: 5px;
`;

class Sidebar extends Component {
  state = {
    links: [
      {
        name: "Agenda",
        icon: "schedule",
        route: "/schedules",
      },
      // { name: 'Caixa', icon: <Icon name={"cashier"} color={this.props.theme.colors.white03} />, route: '/cashier' },
      {
        name: "Comissões",
        icon: "percent",
        route: "/commissions",
      },
      {
        name: "Estatísticas",
        icon: "statistics",
        route: "/statistics",
      },
      {
        name: "Clientes",
        icon: "clients",
        route: "/clients",
      },
      {
        name: "Produtos",
        icon: "products",
        route: "/products",
      },
      {
        name: "Comandas",
        icon: "order",
        route: "/orders",
      },
      {
        name: "Funcionários",
        icon: "clients",
        route: "/workers",
      },
    ],
    bottomLinks: [
      {
        name: "Configurações",
        icon: "settings",
        route: "/settings",
        action: () => {},
      },
      {
        name: "Sair",
        icon: "logout",
        route: "/",
        action: () => {
          localStorage.clear();
        },
      },
    ],
  };

  render() {
    const { links, bottomLinks } = this.state;
    return (
      <SidebarContainer>
        <SidebarContainerFixed>
          <Item to="/schedules" margin="0 0 15px">
            <Logo src={configTheme().barberLogo} />
          </Item>
          <HeightBetween>
            <Column>
              {links.map((link, i) => (
                <Item
                  to={link.route}
                  key={i}
                  active={window.location.href.includes(link.route)}
                  margin={"15% 0"}
                >
                  <Icon
                    name={link.icon}
                    color={this.props.theme.colors.fontSidebar}
                  />
                  <TextIcon> {link.name} </TextIcon>
                </Item>
              ))}
            </Column>
            <Row around marginBottom="10px">
              {bottomLinks.map((bottomLinks, i) => (
                <Item
                  to={bottomLinks.route}
                  key={i}
                  onClick={bottomLinks.action}
                >
                  <Icon
                    name={bottomLinks.icon}
                    color={this.props.theme.colors.white03}
                  />
                </Item>
              ))}
            </Row>
          </HeightBetween>
        </SidebarContainerFixed>
      </SidebarContainer>
    );
  }
}

export default withTheme(withRouter(Sidebar));
