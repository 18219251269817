import styled from "styled-components";
import Icon from "../../Icons/Icon";
import { Row, Column } from "../../General/StyledItems";
import Modal from "../../UIComponents/Modal";

export const Td = styled.td`
  width: 20%;
  border-top: 0;
  text-align: left;
  font-size: 0.85em;
  a {
    color: inherit;
    padding-bottom: 2px;
    border-bottom: 1px solid ${props => props.theme.colors.primary03};
    display: inline-block;
  }
`;
export const Th = styled.th`
  padding: 1em 0;
  width: 20%;
  border-top: 0;
  color: ${props => props.theme.colors.fontPrimary};
  font-size: 0.85em;
  text-align: left;
`;

export const Tr = styled.tr`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.gray};
  padding: 10px 0;
  color: ${props => props.theme.colors.primary};
  width: 100%;
  transition: .3s;

  ${({ checked }) =>
    checked &&
    `
    color: ${props => props.theme.colors.lightGreen};
  `}
  ${({ disabled }) =>
    disabled &&
    `
    color: ${props => props.theme.colors.primary};
    opacity: .3;
  `}
   ${Td}:first-child, ${Th}:first-child {
     width: 30px;
   }
  `;

export const TableIcon = styled(Icon)`
  margin-right: 0;
  cursor: pointer;
  transition: 0.4s;
  will-change: margin-right;
  ${({ marginRight }) =>
    marginRight &&
    `
    margin-right: ${marginRight};
  `}
  ${({ marginLeft }) =>
    marginLeft &&
    `
    margin-left: ${marginLeft};
  `}
`;

export const TableIconsContainer = styled(Row)`
  align-items: center;
  justify-content: space-around;
  margin-left: -5em;
  opacity: 0;
  transition: 0.4s;
  pointer-events: none;
  transform: scale(0.6);
  ${props =>
    props.active &&
    `
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
  `}
`;

export const ShowModal = styled(Modal)`
  color: ${props => props.theme.colors.fontPrimary};
  h1 {
    font-size: 24px;
    margin: 10px 0;
  }
`;

export const Content = styled(Column)`
  width: 100%;
  margin-top: 3%;
`;
export const ButtonAddNew = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.primary};
  color: white;
  width: 11em;
  font-size: 0.8em;
  padding: 0.4em 0em;
  border-radius: 3px;
  margin-top: 1%;
  font-weight: bold;
  margin-left: auto;
`;

export const IconAddNew = styled.img`
  margin-right: 4px;
`;

export const ModalColumn = styled(Column)`
  width: 100%;
  justify-content: space-between;
`;

export const ModalInput = styled.input`
  width: ${({ width }) => width};
  border: 1px solid ${props => props.theme.colors.fontPrimary};
  padding: 0.5em 0.1em;
  font-size: 0.9em;
  color: ${props => props.theme.colors.fontPrimary};
  width: 100%;
  &:disabled {
    opacity: 0.4;
    background-color: ${props => props.theme.colors.fontPrimary01};
  }
`;

export const ColumnName = styled.p`
  color: ${props => props.theme.colors.primary};
  font-size: 0.9em;
  margin-top: 5%;

  &:nth-child(2) {
    margin-top: 8%;
  }
`;

export const ButtonSave = styled.button`
  padding: 0.5em 1.2em;
  margin-right: 2%;
  color: ${props => props.theme.colors.fontTertiary};
  font-size: 0.8em;
  background: ${props => props.theme.colors.primary};
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.primary};

  &:hover {
    background: white;
    color: ${props => props.theme.colors.primary};
    transition: all 0.25s ease;
  }
`;

export const Checkboxcontainer = styled.div`
  margin-top: 0.8em;
`;
