import React, { useEffect, useState } from "react";
import {
  FormLabel,
  ModalInput,
  ModalColumn,
  MaskedCurrencyInput,
} from "./StyledForm";
import { ErrorMessage } from "formik";
import styled from "styled-components";
import { Square } from "./StyledItems";
import StyledSelect from "./StyledSelect";

const CheckboxLabel = styled(FormLabel)`
  display: flex;
  align-items: center;
  margin: 10px 0;
  color: ${(props) => props.theme.colors.fontPrimary};
  cursor: pointer;
  ${Square} {
    border: 2px solid currentColor;
    border-radius: 2px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 150ms;
    &:before {
      content: "✔";
      font-size: 0.7em;
      transform: scale(0.3);
      transition: opacity 150ms, transform 150ms;
      will-change: transform;
      opacity: 0;
    }
  }

  input:checked ~ ${Square} {
    color: ${(props) => props.theme.colors.lightGreen};
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export function FormWithoutEnter(props) {
  return (
    <form onKeyPress={(e) => e.which === 13 && e.preventDefault()} {...props} />
  );
}

export function FieldWithMaskError({
  name,
  placeholder,
  disabled,
  required,
  error,
  touched,
  InputComponent = ModalInput,
  label,
  type,
  onChange,
  onBlur,
  value: valueProp,
  inputMask,
  ...props
}) {
  // const valueProp = inputMask ? { defaultValue: value } : { value }
  const [value, setValue] = useState(valueProp);
  useEffect(() => {
    setValue(valueProp);
    return () => {};
  }, [valueProp]);
  return (
    <ModalColumn className={disabled && "disabled"}>
      {label && (
        <FormLabel
          className={touched && error && "have-error"}
          required={required}
        >
          {label}
        </FormLabel>
      )}
      <InputComponent
        className={touched && error && "have-error"}
        placeholder={placeholder}
        onAccept={(mask) => {
          setValue(mask);
          onChange(name, mask);
        }}
        onBlur={(e) => {
          onChange(name, value);
          onBlur(e);
        }}
        name={name}
        type={type}
        inputMask={inputMask}
        value={value}
        {...props}
      />
      <ErrorMessage component="span" className="have-error" name={name} />
    </ModalColumn>
  );
}

export function FieldWithCurrencyMaskError({
  name,
  placeholder,
  disabled,
  required,
  error,
  touched,
  label,
  type,
  onChange,
  onBlur,
  value: valueProp,
  inputMask,
  ...props
}) {
  const [value, setValue] = useState(valueProp);

  useEffect(() => {
    setValue(valueProp);
    return () => {};
  }, [valueProp]);
  return (
    <ModalColumn className={disabled && "disabled"}>
      {label && (
        <FormLabel
          className={touched && error && "have-error"}
          required={required}
        >
          {label}
        </FormLabel>
      )}
      <MaskedCurrencyInput
        decimalSeparator=","
        thousandSeparator="."
        prefix="R$ "
        error={touched && error}
        placeholder=" "
        value={value}
        name={name}
        required
        onChangeEvent={(e, mask, float) => {
          onChange(name, float);
          setValue(float);
        }}
        onBlur={(e) => {
          onChange(name, value);
          onBlur(e);
        }}
        {...props}
      />
      <ErrorMessage component="span" className="have-error" name={name} />
    </ModalColumn>
  );
}
export function FieldWithMultiSelectError({
  name,
  placeholder,
  disabled,
  required,
  error,
  touched = true,
  InputComponent = ModalInput,
  label,
  type,
  onChange,
  onBlur,
  value: valueProp,
  inputMask,
  ...props
}) {
  const [value, setValue] = useState(valueProp);
  useEffect(() => {
    setValue(valueProp);
    return () => {};
  }, [valueProp]);
  return (
    <ModalColumn className={disabled && "disabled"}>
      {label && (
        <FormLabel
          className={touched && error && "have-error"}
          required={required}
        >
          {label}
        </FormLabel>
      )}
      <InputComponent
        className={touched && error && "have-error"}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e);
          onChange(e);
        }}
        error={touched && error}
        onBlur={(e) => {
          onChange(value);
          onBlur(e);
        }}
        name={name}
        type={type}
        inputMask={inputMask}
        value={value}
        {...props}
      />
      {error && touched && <span className="have-error">{error}</span>}
    </ModalColumn>
  );
}
export function FieldWithSelectError({
  name,
  placeholder,
  disabled,
  required,
  error,
  touched,
  InputComponent = StyledSelect,
  label,
  type,
  onChange,
  onBlur,
  value: valueProp,
  inputMask,
  ...props
}) {
  const [value, setValue] = useState(valueProp);
  useEffect(() => {
    setValue({ ...value, ...valueProp });
    return () => {};
  }, [valueProp]);
  return (
    <ModalColumn className={disabled && "disabled"}>
      {label && (
        <FormLabel
          className={touched && error && "have-error"}
          required={required}
        >
          {label}
        </FormLabel>
      )}
      <InputComponent
        className={touched && error && "have-error"}
        placeholder={placeholder}
        onChange={(e) => {
          onChange({ label: e.label || e.value, value: e.value, ...e });
          setValue({ label: e.label || e.value, value: e.value, ...e });
        }}
        onBlur={(e) => {
          onChange(value);
          onBlur(e);
        }}
        id={name}
        name={name}
        error={touched && error}
        type={type}
        inputMask={inputMask}
        value={value}
        {...props}
      />
      {error && touched && <span className="have-error">{error}</span>}
    </ModalColumn>
  );
}

export function FieldWithError({
  name,
  placeholder,
  disabled,
  required,
  error,
  touched,
  InputComponent = ModalInput,
  label,
  type,
  onChange,
  onBlur,
  value: valueProp,
  inputMask,
  ...props
}) {
  // const [value, setValue] = useState(valueProp);
  // useEffect(() => {
  //   setValue(valueProp);
  //   return () => {};
  // }, [valueProp]);
  return (
    <ModalColumn className={disabled && "disabled"}>
      {label && (
        <FormLabel
          className={touched && error && "have-error"}
          required={required}
        >
          {label}
        </FormLabel>
      )}
      <InputComponent
        className={touched && error && "have-error"}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        onBlur={(e) => {
          onChange(e);
          onBlur(e);
        }}
        name={name}
        type={type}
        inputMask={inputMask}
        value={valueProp}
        {...props}
      />
      {error && touched && <span className="have-error">{error}</span>}
    </ModalColumn>
  );
}

export function ArrayCheckboxWithError({
  index,
  error,
  touched,
  remove,
  push,
  value = [],
  setFieldValue,
  name,
  arrayName,
  label,
}) {
  return (
    <>
      <CheckboxLabel>
        <input
          type="checkbox"
          name={name}
          hidden
          checked={value.includes(index)}
          onChange={(e) => {
            if (e.target.checked) {
              setFieldValue(arrayName, [...value, index]);
            } else {
              setFieldValue(
                arrayName,
                value.filter((v) => v !== index)
              );
            }
          }}
        />
        <Square />
        <p>{label}</p>
      </CheckboxLabel>
      {error && touched && <span className="have-error">{error}</span>}
      <ErrorMessage component="span" className="have-error" name={name} />
    </>
  );
}
