import { lazy } from "react";
export default [
  {
    exact: true,
    path: "/schedules",
    component: lazy(() => import("../screens/Schedules_2")),
  },

  {
    exact: true,
    path: "/statistics",
    component: lazy(() => import("../screens/Statistics")),
  },

  {
    exact: true,
    path: "/statistics/:subroute",
    component: lazy(() => import("../screens/Statistics")),
  },

  {
    exact: true,
    path: "/cashier",
    component: lazy(() => import("../screens/Cashier")),
  },

  {
    exact: true,
    path: "/cashier/new-entry",
    component: lazy(() => import("../components/Cashier/NewEntry")),
  },

  {
    exact: true,
    path: "/cashier/new-output",
    component: lazy(() => import("../components/Cashier/NewOutput")),
  },

  {
    exact: true,
    path: "/clients",
    component: lazy(() => import("../screens/Clients")),
  },

  {
    exact: true,
    path: "/orders",
    component: lazy(() => import("../screens/Orders")),
  },

  {
    exact: true,
    path: "/settings",
    component: lazy(() => import("../screens/Settings")),
  },

  {
    exact: true,
    path: "/products",
    component: lazy(() => import("../screens/Products")),
  },
  {
    exact: true,
    path: "/products/:subroute",
    component: lazy(() => import("../screens/Products")),
  },

  {
    exact: true,
    path: "/workers",
    component: lazy(() => import("../screens/Workers")),
  },
  {
    exact: true,
    path: "/settings/restrictions",
    component: lazy(() => import("../screens/Restrictions")),
  },

  {
    exact: true,
    path: "/commissions",
    component: lazy(() => import("../screens/Commissions")),
  },

  {
    exact: true,
    path: "/settings/company-info",
    component: lazy(() => import("../screens/CompanyInfo")),
  },
  {
    exact: true,
    path: "/settings/payments-type",
    component: lazy(() => import("../screens/PaymentType")),
  },
  // {
  //   exact: true,
  //   path: '/settings/messages-config',
  //   component: lazy(() => import('../screens/MessagesConfig'))
  // },
  {
    exact: true,
    path: "/settings/permissions",
    component: lazy(() => import("../screens/Permissions")),
  },
  {
    exact: true,
    path: "/settings/fidelity-card",
    component: lazy(() => import("../screens/Fidelity")),
  },
];
