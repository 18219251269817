import gql from "graphql-tag";

export const GET_CUSTOMERS_NAMES = gql`
  query users($page: Int!, $paginate: Int) {
    users(role: "customer", page: $page, paginate: $paginate) {
      docs {
        id
        name
      }
    }
  }
`;
export const GET_ADMINS = gql`
  query users($page: Int!, $paginate: Int) {
    users(role: "admin", page: $page, paginate: $paginate) {
      docs {
        id
        email
        name
      }
    }
  }
`;

export const GET_CLIENTS = gql`
  query users(
    $birthdayThisMonth: Boolean
    $notificationToken: Boolean
    $page: Int!
    $paginate: Int
    $order: [String!]
    $name: String
  ) {
    users(
      role: "customer"
      name: $name
      page: $page
      paginate: $paginate
      order: $order
      notificationToken: $notificationToken
      birthdayThisMonth: $birthdayThisMonth
    ) {
      docs {
        id
        name
        email
        phone
        birthday
        avatar
        fidelity
        allow_schedule_app
        orders {
          id
          discount
          totalDiscountWithServices
          price_order
          status
          date
          updatedAt
          customer {
            id
            name
            fidelity
          }
          status
          total_price
          schedules {
            id
            schedule_to
            customer {
              id
              name
            }
            professional {
              id
              name
            }
            service {
              id
              title
              price
              description
            }
            discount
          }
          products {
            id
            name
            amount
            price
            unit_type
          }
        }
        address {
          id
          complement
          street
          number
          district
          city
          uf
        }
      }
      pages
      total
    }
  }
`;
export const GET_CLIENTS_MODAL = gql`
  query users(
    $birthdayThisMonth: Boolean
    $notificationToken: Boolean
    $page: Int!
    $paginate: Int
    $order: [String!]
    $name: String
  ) {
    users(
      role: "customer"
      name: $name
      page: $page
      paginate: $paginate
      order: $order
      notificationToken: $notificationToken
      birthdayThisMonth: $birthdayThisMonth
    ) {
      docs {
        id
        name
        email
        phone
        birthday
        avatar
        fidelity
        allow_schedule_app
        address {
          id
          complement
          street
          number
          district
          city
          uf
        }
      }
      pages
      total
    }
  }
`;
export const FILTER_CLIENTS = gql`
  query filter($fil: String!, $withNotificationToken: Boolean) {
    filter(fil: $fil, withNotificationToken: $withNotificationToken) {
      id
      name
      email
      phone
      orders {
        id
        discount
        totalDiscountWithServices
        price_order
        status
        date
        deletedAt
        updatedAt
        status
        total_price
        schedules {
          id
          schedule_to
        }
      }
    }
  }
`;

export const GET_PROFESSIONALS_PER_SERVICE = gql`
  query professionalsPerServices($serviceName: String!) {
    professionalsPerServices(serviceName: $serviceName) {
      id
      name
      email
      phone
      birthday
      avatar
      fidelity
    }
  }
`;

export const GET_ADDRESS = gql`
  query findAddressByCep($cep: String!) {
    findAddressByCep(data: { cep: $cep }) {
      cep
      logradouro
      complemento
      bairro
      localidade
      uf
      unidade
      ibge
      gia
    }
  }
`;

export const GET_CUSTOMER_SCHEDULES = gql`
  query schedules($userId: ID!) {
    schedules(userId: $userId) {
      id
      schedule_to
      service {
        id
        title
        duration
        price
        employees {
          id
          name
        }
      }
      app
      customer {
        id
        name
        phone
        email
        avatar
        birthday
        address {
          id
          street
          number
          district
          uf
          city
        }
        role {
          id
          display_name
        }
      }
      orders {
        id
        date
        status
        discount
        totalDiscountWithServices
        price_order
        total_price
        deletedAt
        customer {
          id
          name
          fidelity
        }
        schedules {
          id
          schedule_to
          service {
            id
            title
            price
            description
          }
          customer {
            id
            name
          }
          professional {
            id
            name
          }
          discount
        }
        products {
          id
          product_id
          amount
          name
          price
          unit_type
        }
      }
      professional {
        id
        name
      }
      discount
    }
  }
`;

export const GET_SCHEDULES = gql`
  query schedules {
    schedules {
      id
      schedule_to
      service {
        id
        title
        employees {
          id
          name
        }
      }
      customer {
        id
        name
      }
      professional {
        id
        name
      }
    }
  }
`;

export const GET_COMISSIONS_BY_PROFESSIONAL = gql`
  query commissionsByProfessional(
    $id: ID!
    $page: Int!
    $paginate: Int!
    $period: FilterPeriod
  ) {
    commissionsByProfessional(
      id: $id
      page: $page
      paginate: $paginate
      period: $period
    ) {
      docs {
        date
        income
        commission
        discount
        services_performed
        is_paid

        professional {
          name
          id
        }
      }
      pages
      total
    }
  }
`;

export const GET_COMISSIONS_BY_PROFESSIONALS = gql`
  query commissionsByProfessionals($page: Int!, $paginate: Int!) {
    commissionsByProfessionals(page: $page, paginate: $paginate) {
      docs {
        total_commission
        total_income
        total_discount
        total_services_performed
        commissions {
          date
          income
          commission
          services_performed
          discount
          is_paid
          paid_in
          professional {
            id
            name
          }
        }
        professional {
          id
          name
        }
      }
      pages
      total
    }
  }
`;

export const GET_SCHEDULE = gql`
  query schedule($id: ID!) {
    schedule(id: $id) {
      id
      schedule_to
      service {
        id
        title
        employees {
          id
          name
        }
      }
      orders {
        id
        status
        date
        discount
        totalDiscountWithServices
        price_order
        total_price
        customer {
          id
          name
          phone
          email
          avatar
          birthday
          fidelity
          address {
            id
            street
            number
            district
            uf
            city
          }
          role {
            id
            display_name
          }
        }
        schedules {
          id
          schedule_to
          customer {
            id
            name
            phone
            email
            avatar
            birthday
            address {
              id
              street
              number
              district
              uf
              city
            }
            role {
              id
              display_name
            }
          }
          professional {
            id
            name
          }
          service {
            id
            title
            price
            description
          }
          discount
        }
        products {
          id
          name
          amount
          price
          unit_type
        }
      }
      customer {
        id
        name
        phone
        email
        avatar
        birthday
        address {
          id
          street
          number
          district
          uf
          city
        }
        role {
          id
          display_name
        }
      }
      professional {
        id
        name
      }
      discount
    }
  }
`;

export const GET_SCHEDULES_SCREEN = gql`
  query {
    schedules {
      id
      schedule_to
      service {
        id
        title
        duration
        employees {
          id
          name
        }
      }
      app
      customer {
        id
        name
        phone
        email
        avatar
        birthday
        address {
          id
          street
          number
          district
          uf
          city
        }
        role {
          id
          display_name
        }
      }
      orders {
        id
        date
        status
        discount
        totalDiscountWithServices
        price_order
        total_price
        deletedAt
        customer {
          id
          name
          fidelity
        }
        schedules {
          id
          schedule_to
          service {
            id
            title
            price
            description
          }
          customer {
            id
            name
          }
          professional {
            id
            name
          }
          discount
        }
        products {
          id
          product_id
          amount
          name
          price
          unit_type
        }
      }
      professional {
        id
        name
      }
      discount
    }

    services {
      id
      title
      duration
      employees {
        id
        name
      }
    }

    users(role: "customer", page: 1, paginate: 9999) {
      docs {
        id
        name
        phone
        email
        avatar
        birthday
        address {
          id
          street
          number
          district
          uf
          city
        }
        role {
          id
          display_name
        }
        services {
          id
          title
          description
          employees {
            id
            name
          }
        }
        workJourney {
          id
          morningStartAt
          morningEndAt
          eveningStartAt
          eveningEndAt
        }
      }
      pages
    }

    professionals: users(role: "employee", page: 1, paginate: 9999) {
      docs {
        id
        name
        phone
        email
        avatar
        birthday
        address {
          id
          street
          number
          district
          uf
          city
        }
        role {
          id
          display_name
        }
        services {
          id
          title
          description
          employees {
            id
            name
          }
        }
        workJourney {
          id
          morningStartAt
          morningEndAt
          eveningStartAt
          eveningEndAt
        }
      }
      pages
      total
    }
  }
`;

export const GET_SCHEDULES_PER_DAY = gql`
  query schedules($day: String!) {
    schedules(day: $day) {
      id
      schedule_to
      service {
        id
        title
        duration
        employees {
          id
          name
        }
      }
      app
      customer {
        id
        name
        phone
        email
        avatar
        birthday
        address {
          id
          street
          number
          district
          uf
          city
        }
        role {
          id
          display_name
        }
      }
      orders {
        id
        date
        status
        discount
        totalDiscountWithServices
        price_order
        total_price
        deletedAt
        customer {
          id
          name
          fidelity
        }
        schedules {
          id
          schedule_to
          service {
            id
            title
            price
            description
          }
          customer {
            id
            name
          }
          professional {
            id
            name
          }
        }
        products {
          id
          product_id
          amount
          name
          price
          unit_type
        }
      }
      professional {
        id
        name
      }
    }
  }
`;

export const GET_CALENDAR_2_SCREEN = gql`
  query schedules($day: String!) {
    schedules(day: $day) {
      id
      schedule_to
      status
      service {
        id
        title
        duration
        description
        employees {
          id
          name
          # deletedAt
        }
      }
      app
      customer {
        id
        name
        phone
        email
        avatar
        birthday
        address {
          id
          street
          number
          district
          uf
          city
        }
        role {
          id
          display_name
        }
        # deletedAt
      }
      orders {
        id
        date
        status
        discount
        totalDiscountWithServices
        price_order
        total_price
        deletedAt
        customer {
          id
          name
          fidelity
          # deletedAt
        }
        schedules {
          id
          status
          schedule_to
          service {
            id
            title
            price
            description
          }
          customer {
            id
            name
            # deletedAt
          }
          professional {
            id
            name
            # deletedAt
          }
          discount
        }
        products {
          id
          product_id
          amount
          name
          price
          unit_type
        }
      }
      professional {
        id
        name
        # deletedAt
      }
    }

    restrictions(page: 1, paginate: 99999) {
      docs {
        id
        time_end
        date_start
        date_end
        number_of_weeks
        day_of_week
        time_start
        workJourney {
          id
          professional {
            id
            name
            email
          }
        }
      }
      pages
    }

    professionals: users(role: "employee", page: 1, paginate: 9999) {
      docs {
        id
        name
        phone
        email
        avatar
        birthday
        address {
          id
          street
          number
          district
          uf
          city
        }
        role {
          id
          display_name
        }
        services {
          id
          title
          description
          employees {
            id
            name
          }
        }
        workJourney {
          id
          morningStartAt
          morningEndAt
          eveningStartAt
          eveningEndAt
        }
        # deletedAt
      }
      pages
      total
    }

    services {
      id
      title
      duration
      employees {
        id
        name
        # deletedAt
      }
    }

    users(role: "customer", page: 1, paginate: 15) {
      docs {
        id
        name
        phone
        email
        avatar
        birthday
        address {
          id
          street
          number
          district
          uf
          city
        }
        role {
          id
          display_name
        }
        services {
          id
          title
          description
          employees {
            id
            name
          }
        }
        workJourney {
          id
          morningStartAt
          morningEndAt
          eveningStartAt
          eveningEndAt
        }
        # deletedAt
      }
      pages
    }

    company {
      id
      name
      email
      cnpj
      business_name
      phone
      businessHours {
        id
        day
        morning_open
        morning_close
        afternoon_open
        afternoon_close
      }
      address {
        id
        street
        number
        district
        complement
        uf
        city
      }
      maxDaysForScheduling
      commissionCalculationMethod
    }
  }
`;

export const GET_EMPLOYEE_TIMES = gql`
  query availableScheduleDates($service_id: Int!, $professional_id: Int!) {
    availableScheduleDates(
      data: { service_id: $service_id, professional_id: $professional_id }
    ) {
      date
      available
    }
  }
`;

// export const GET_SCHEDULES_BY_PROFESSIONALS = gql`
//   query schedulesByProfessional ( $id: ID! ){
//     schedulesByProfessional(id: $id){
//     id
//     schedule_to
//     service{
//       id
//       title
//       price
//       duration
//       expiration
//       employee{
//         id
//         name
//       }
//     }
//     customer{
//       id
//       name
//       email
//       avatar
//     }
//     professional{
//       id
//       name
//     }
//   }
// }
// `

export const GET_WORKERS = gql`
  query users($page: Int!, $paginate: Int, $deleted: Boolean) {
    users(
      role: "employee"
      page: $page
      paginate: $paginate
      deleted: $deleted
    ) {
      docs {
        id
        name
        phone
        email
        avatar
        birthday
        address {
          id
          street
          number
          district
          uf
          city
          complement
        }
        role {
          id
          display_name
        }
        services {
          id
          title
          description
        }
        workJourney {
          id
          morningStartAt
          morningEndAt
          eveningStartAt
          eveningEndAt
        }
      }
      pages
      total
    }
  }
`;

export const GET_USERS = gql`
  query users($page: Int!, $paginate: Int) {
    users(page: $page, paginate: $paginate) {
      docs {
        id
        name
        phone
        email
        avatar
        birthday
        address {
          id
          street
          number
          district
          uf
          city
        }
        role {
          id
          display_name
          name
          permissions {
            id
            name
          }
        }
        services {
          id
          title
          description
        }
      }
      pages
      total
    }
  }
`;

export const GET_NOT_CLIENTS = gql`
  query notClients($page: Int!, $paginate: Int) {
    notClients(page: $page, paginate: $paginate) {
      docs {
        id
        name
        phone
        email
        avatar
        birthday
        address {
          id
          street
          number
          district
          uf
          city
        }
        role {
          id
          display_name
          name
          permissions {
            id
            name
          }
        }
        services {
          id
          title
          description
        }
      }
      pages
      total
    }
  }
`;

export const GET_WORKER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      name
      phone
      email
      avatar
      birthday
      address {
        id
        street
        number
        district
        uf
        city
        complement
      }
      role {
        id
        display_name
      }
      services {
        id
        title
        description
      }
    }
  }
`;

export const GET_SERVICES = gql`
  {
    users(role: "employee") {
      id
      name
      phone
      email
      avatar
      birthday
      address {
        id
        street
        number
        district
        uf
        city
        complement
      }
      role {
        id
        display_name
      }
      services {
        id
        title
        description
      }
    }
  }
`;

export const GET_ALL_SERVICES_SCREEN = gql`
  {
    services {
      id
      title
      description
      price
      employees {
        id
        name
        email
        avatar
        birthday
      }
    }
  }
`;

export const GET_ORDERS = gql`
  query orders(
    $page: Int!
    $paginate: Int
    $filter: FilterOrderInput
    $order: OrderByInput
  ) {
    orders(page: $page, paginate: $paginate, filter: $filter, order: $order) {
      docs {
        id
        date
        status
        total_price
        discount
        totalDiscountWithServices
        price_order
        deletedAt
        customer {
          id
          name
          fidelity
        }
        schedules {
          id
          schedule_to
          service {
            id
            title
            price
            description
          }
          customer {
            id
            name
          }
          professional {
            id
            name
          }
          discount
        }
        products {
          id
          product_id
          amount
          name
          price
          unit_type
        }
      }
      pages
      total
    }
  }
`;

export const GET_PRODUCTS = gql`
  query products($page: Int!, $paginate: Int) {
    products(page: $page, paginate: $paginate) {
      docs {
        id
        name
        sell_price
        amount
        purchase_price
        unit_type
        amount
        minimum_amount
        consumable
      }
      pages
      total
    }
  }
`;

export const GET_ALL_SERVICES = gql`
  query getAllServices {
    services {
      id
      title
      duration
      commission
      price
      description
      expiration
      employees {
        id
        name
      }
    }
  }
`;

export const GET_HISTORIC_CONSUME = gql`
  query productConsume(
    $customerId: ID
    $date: String
    $paginate: Int
    $page: Int!
  ) {
    productsConsume(
      customerId: $customerId
      date: $date
      page: $page
      paginate: $paginate
    ) {
      docs {
        id
        product {
          id
          name
        }
        customer {
          id
          name
        }
        amount
        consume_date
      }
      pages
    }
  }
`;

export const GET_PRODUCTS_CONSUMABLES = gql`
  query productsConsumable {
    productsConsumablesWithHistoric {
      id
      name
      sell_price
      amount
      purchase_price
      unit_type
      amount
      minimum_amount
      consumable
    }
  }
`;

export const GET_WORK_JOURNEYS = gql`
  query workJourneys($professionalId: ID!) {
    workJourneys(professionalId: $professionalId) {
      professional {
        name
        id
      }
      date
      morningStartAt
      morningEndAt
      eveningStartAt
      eveningEndAt
      professional {
        name
      }
      id
    }
  }
`;

export const GET_COMPANY = gql`
  query company {
    company {
      id
      name
      email
      cnpj
      business_name
      phone
      businessHours {
        id
        day
        morning_open
        morning_close
        afternoon_open
        afternoon_close
      }
      address {
        id
        street
        number
        district
        complement
        uf
        city
      }
      maxDaysForScheduling
      commissionCalculationMethod
    }
  }
`;

export const GET_COMPANY_METHOD_COMMISSION = gql`
  query company {
    company {
      id
      commissionCalculationMethod
    }
  }
`;

export const GET_ACTIVE_EMPLOYEES = gql`
  query {
    users(role: "employee", page: 1, paginate: 99999, order: ["id", "asc"]) {
      docs {
        id
        name
      }
    }
  }
`;

export const GET_PAYMENT_TYPES = gql`
  query {
    paymentTypes {
      id
      checked
      type
    }
  }
`;

export const GET_MESSAGE_SETTINGS = gql`
  query {
    messageSettings {
      id
      key
      value
    }
  }
`;

export const GET_ROLES = gql`
  query {
    roles {
      id
      name
      display_name
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query {
    permissions {
      id
      name
      table_name
      display_table_name
      display_name
    }
  }
`;

export const GET_RESTRICTIONS = gql`
  query restrictions(
    $page: Int!
    $paginate: Int
    $filter: RestrictionWhereFilter
  ) {
    restrictions(page: $page, paginate: $paginate, filter: $filter) {
      docs {
        id
        time_end
        date_start
        date_end
        number_of_weeks
        day_of_week
        time_start
        workJourney {
          id
          professional {
            id
            name
            email
          }
        }
      }
      pages
    }
  }
`;

export const VALIDATE_FIELD = gql`
  query validateField($data: ValidateFieldInput!) {
    validateField(data: $data) {
      haveValue
    }
  }
`;
export const GET_PENDING_COMMISSIONS = gql`
  query commissions($page: Int!, $paginate: Int, $filter: FilterExpenseInput) {
    commissionsByProfessionals(
      page: $page
      paginate: $paginate
      filter: $filter
    ) {
      docs {
        professional {
          id
          name
        }
        total_commission
        total_services_performed
        total_discount
        commissions(filter: { is_paid: false }) {
          date
          income
          commission
          services_performed
          discount
        }
      }
      pages
      total
    }
  }
`;
export const GET_ALL_COMMISSIONS_BY_PROFESSIONAL = gql`
  query commissionsByProfessional($id: ID!, $page: Int!, $paginate: Int!) {
    commissionsByProfessional(id: $id, page: $page, paginate: $paginate) {
      docs {
        date
        income
        commission
        services_performed
        is_paid
        discount
        professional {
          name
          id
        }
      }
      pages
      total
    }
  }
`;

export const GET_COMMISSION_DETAIL = gql`
  query commissionByDate($professionalId: ID!, $date: String!) {
    commissionByDate(professionalId: $professionalId, date: $date) {
      schedule {
        id
        schedule_to
        discount
        customer {
          id
          name
        }
        service {
          id
          title
        }
      }
      discount
      price
      full_price
    }
  }
`;

export const GET_ORDERS_TO_FIDELITY_CARD = gql`
  query ordersToFidelityCard($userId: ID!, $limit: Int) {
    ordersToFidelityCard(userId: $userId, limit: $limit) {
      orders {
        id
        status
        discount
        totalDiscountWithServices
        price_order
        total_price
        date
        updatedAt
        schedules {
          id
          service {
            id
            title
            price
          }
          professional {
            id
            name
          }
          discount
        }
        customer {
          id
          name
        }
        products {
          id
          amount
          name
          price
        }
      }
      media
    }
  }
`;

export const DASHBOARD_GERAL = gql`
  query(
    $bestClients_startDate: String!
    $bestClients_interval: Interval!
    $limit: Int!
  ) {
    bestsCustomersOfDateInterval(
      startDate: $bestClients_startDate
      interval: $bestClients_interval
      limit: $limit
    ) {
      name
      totalSpend
    }

    revenueExpenseGainsPerPeriod(
      startDate: $bestClients_startDate
      interval: $bestClients_interval
    ) {
      revenues
      expenses
      gains
    }

    mostProftableServicesOfDateInterval(
      startDate: $bestClients_startDate
      interval: $bestClients_interval
      limit: $limit
    ) {
      title
      totalGains
    }

    schedulesPerPeriod(
      startDate: $bestClients_startDate
      interval: $bestClients_interval
    ) {
      actual {
        doneSchedule
        canceledSchedule
      }
      before {
        doneSchedule
        canceledSchedule
      }
    }

    registerClientsPerPeriod(
      startDate: $bestClients_startDate
      interval: $bestClients_interval
    ) {
      actual
      before
    }

    schedulesByAppAndWeb(
      startDate: $bestClients_startDate
      interval: $bestClients_interval
    ) {
      app
      web
    }

    mostDoneServicesByProfessionals(
      startDate: $bestClients_startDate
      interval: $bestClients_interval
    ) {
      service {
        title
      }
      employees {
        employee {
          name
        }
        count
      }
      count
    }
  }
`;
