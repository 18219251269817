import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { withTheme } from "styled-components";

function StyledSelect({
  options,
  theme: styledTheme,
  isLoading,
  loadOptionsAsync,
  refTo,
  value,
  isMulti,
  error,
  isClearable,
  onChange,
  defaultValue,
  placeholder = "Selecione um",
  noOptionsMessage = () => "Nenhuma opção",
  ...props
}) {
  if (options && options.find(option => option.search)) {
    props.filterOption = (option, rawInput) => {
      return option.data.search.toLowerCase().includes(rawInput.toLowerCase());
    };
  }

  if (refTo) {
    props.ref = input => refTo(input);
  }

  if (loadOptionsAsync) {
    return (
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptionsAsync}
        defaultOptions={options}
        placeholder={placeholder}
        noOptionsMessage={noOptionsMessage}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        inputId={props.name}
        loadingMessage={() => "Carregando"}
        isClearable={isClearable}
        styles={{
          container: e => ({
            ...e,
            width: "100%",
          }),
          control(e) {
            return error
              ? {
                  ...e,
                  borderColor: styledTheme.colors.error,
                }
              : e;
          },
          menu(e) {
            return {
              ...e,
              zIndex: 60,
              // position: 'relative',
            };
          },
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: styledTheme.colors.primary,
            primary25: styledTheme.colors.primary03,
            primary75: styledTheme.colors.primary07,
            primary50: styledTheme.colors.primary05,
          },
        })}
        {...props}
      />
    );
  } else {
    return (
      <Select
        placeholder={placeholder}
        options={options}
        noOptionsMessage={noOptionsMessage}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        inputId={props.name}
        isClearable={isClearable}
        isLoading={isLoading}
        styles={{
          container: e => ({
            ...e,
            width: "100%",
          }),
          control(e) {
            return error
              ? {
                  ...e,
                  borderColor: styledTheme.colors.error,
                }
              : e;
          },
          menu(e) {
            return {
              ...e,
              zIndex: 60,
              // position: 'relative',
            };
          },
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: styledTheme.colors.primary,
            primary25: styledTheme.colors.primary03,
            primary75: styledTheme.colors.primary07,
            primary50: styledTheme.colors.primary05,
          },
        })}
        {...props}
      />
    );
  }
}

export default withTheme(StyledSelect);
