import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

const INITIAL_STATE = Immutable({
  workerFunctions: [],
  edit: {
    title: "",
    description: "",
    price: 0,
    duration: 0,
    expiration: 0,
  },
  new: {
    title: "",
    description: "",
    price: 0,
    expiration: 0,
  },
});

export const { Types, Creators: WorkerFunctionsActions } = createActions({
  updateWorkerFunctions: ["workerFunctions"],
  updateWorkerFunctionsEdit: ["workerFunction"],
  updateWorkerFunctionsNew: ["workerFunction"],
  resetWorkerFunctionsNew: [""],
  resetWorkerFunctionsEdit: [""],
});

const updateWorkerFunctions = (state = INITIAL_STATE, { workerFunctions }) =>
  state.merge({ workerFunctions }, { deep: true });
const updateEdit = (state = INITIAL_STATE, { workerFunction }) =>
  state.merge({ edit: workerFunction }, { deep: true });
const updateNew = (state = INITIAL_STATE, { workerFunction }) =>
  state.merge({ new: workerFunction }, { deep: true });

export default createReducer(INITIAL_STATE, {
  [Types.UPDATE_WORKER_FUNCTIONS]: updateWorkerFunctions,
  [Types.UPDATE_WORKER_FUNCTIONS_EDIT]: updateEdit,
  [Types.UPDATE_WORKER_FUNCTIONS_NEW]: updateNew,
  [Types.RESET_WORKER_FUNCTIONS_NEW]: (state = INITIAL_STATE) =>
    state.merge({ new: INITIAL_STATE.new }, { deep: true }),
  [Types.RESET_WORKER_FUNCTIONS_EDIT]: (state = INITIAL_STATE) =>
    state.merge({ edit: INITIAL_STATE.edit }, { deep: true }),
});
