import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import colors from "../components/Theme/colors";

export const toastConfig = (appeareance = "success") => ({
  appearance: appeareance,
  autoDismiss: true,
  pauseOnHover: false,
});

export default () => {
  const MySwal = withReactContent(Swal);

  const Toast = MySwal.mixin({
    toast: true,
    customClass: "z-top",
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const Confirm = MySwal.mixin({
    type: "question",
    showCloseButton: true,
    showCancelButton: true,
    customClass: "z-top",
    focusConfirm: false,
    confirmButtonText: "Sim",
    confirmButtonAriaLabel: "Sim",
    confirmButtonColor: colors().primary,
    cancelButtonText: "Não",
    cancelButtonAriaLabel: "Não",
    cancelButtonColor: colors().cancel,
  });

  return {
    Toast,
    Confirm,
  };
};
