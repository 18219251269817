// import Login from '../screens/Login'
import { lazy } from "react";
export default [
  {
    exact: true,
    path: "/",
    redirect: "/login",
    component: lazy(() => import("../screens/Login")),
  },
  {
    exact: true,
    path: "/login",
    component: lazy(() => import("../screens/Login")),
  },
];
