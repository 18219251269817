import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
const INITIAL_STATE = Immutable({
  users: [],
  edit: {
    name: "",
    email: "",
    password: "",
    phone: "",
    birthday: "",
    address: {
      street: "",
      number: "",
      district: "",
      complement: "",
      uf: "",
      city: "",
    },
    services: [],
  },
  new: {
    name: "",
    email: "",
    phone: "",
    birthday: "",
    address: {
      street: "",
      number: "",
      district: "",
      complement: "",
      uf: "",
      city: "",
    },
    services: [],
  },
  pages: 1,
  page: 1,
});

export const { Types, Creators: UserActions } = createActions({
  updateUsers: ["users", "pages"],
  updatePage: ["page"],
  updateEdit: ["user"],
  updateNew: ["user"],
  resetNew: [""],
  resetEdit: [""],
});

const updateUsers = (state = INITIAL_STATE, { users, pages }) =>
  state.merge({ users, pages }, { deep: true });
const updatePage = (state = INITIAL_STATE, { page }) =>
  state.merge({ page }, { deep: true });
const updateEdit = (state = INITIAL_STATE, { user }) =>
  state.merge({ edit: user }, { deep: true });
const updateNew = (state = INITIAL_STATE, { user }) =>
  state.merge({ new: user }, { deep: true });

export default createReducer(INITIAL_STATE, {
  [Types.UPDATE_USERS]: updateUsers,
  [Types.UPDATE_PAGE]: updatePage,
  [Types.UPDATE_EDIT]: updateEdit,
  [Types.UPDATE_NEW]: updateNew,
  [Types.RESET_NEW]: (state = INITIAL_STATE) =>
    state.merge({ new: INITIAL_STATE.new }, { deep: true }),
  [Types.RESET_EDIT]: (state = INITIAL_STATE) =>
    state.merge({ edit: INITIAL_STATE.edit }, { deep: true }),
});
