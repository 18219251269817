/* eslint-disable no-console */
import React from "react";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from "apollo-link-ws";
import { ApolloProvider as Provider } from "react-apollo";
import { getMainDefinition } from "apollo-utilities";
import { ApolloLink, split } from "apollo-link";
import { ApolloProvider as ApolloProviderHook } from "react-apollo-hooks";
import { API_URL, WS_API_URL } from "./consts";

export default function ApolloProvider({ children }) {
  const wsLink = new WebSocketLink({
    uri: WS_API_URL + "/graphql",
    options: {
      reconnect: true,
    },
  });

  const terminatingLink = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    createUploadLink({
      uri: API_URL + "/graphql",
      headers: {
        authorization: "Bearer " + window.localStorage.getItem("token"),
      },
    })
  );

  const client = new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message} | Location: ${JSON.stringify(
                locations
              )}, Path: ${path}`
            )
          );
        }
        if (networkError) {
          console.log(`[Network Status]: ${networkError.statusCode}`);
          console.log(`[Network error]: ${networkError}`);
          if (networkError.statusCode === 401) {
            window.localStorage.removeItem("token");
            window.location.href = "/";
          }
        }
      }),
      terminatingLink,
    ]),
    cache: new InMemoryCache(),
  });
  return (
    <Provider client={client}>
      <ApolloProviderHook client={client}>{children}</ApolloProviderHook>
    </Provider>
  );
}
