import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
const INITIAL_STATE = Immutable({
  products: [],
  edit: {
    id: "",
    name: "",
    sell_price: 0,
    amount: 0,
    purchase_price: 0,
    unit_type: "",
    minimum_amount: 0,
    consumable: false,
  },
  new: {
    id: "",
    name: "",
    sell_price: 0,
    amount: 0,
    purchase_price: 0,
    unit_type: "",
    minimum_amount: 0,
    consumable: false,
  },
  pages: 1,
  page: 1,
});

export const { Types, Creators: ProductActions } = createActions({
  updateProducts: ["products", "pages"],
  updatePage: ["page"],
  updateProductEdit: ["product"],
  updateProductNew: ["product"],
  resetProductNew: [""],
  resetProductEdit: [""],
});

const updateProducts = (state = INITIAL_STATE, { products, pages }) =>
  state.merge({ products, pages }, { deep: true });
const updatePage = (state = INITIAL_STATE, { page }) =>
  state.merge({ page }, { deep: true });
const updateEdit = (state = INITIAL_STATE, { product }) =>
  state.merge({ edit: product }, { deep: true });
const updateNew = (state = INITIAL_STATE, { product }) =>
  state.merge({ new: product }, { deep: true });

export default createReducer(INITIAL_STATE, {
  [Types.UPDATE_PRODUCTS]: updateProducts,
  [Types.UPDATE_PAGE]: updatePage,
  [Types.UPDATE_PRODUCT_EDIT]: updateEdit,
  [Types.UPDATE_PRODUCT_NEW]: updateNew,
  [Types.RESET_PRODUCT_NEW]: (state = INITIAL_STATE) =>
    state.merge({ new: INITIAL_STATE.new }, { deep: true }),
  [Types.RESET_PRODUCT_EDIT]: (state = INITIAL_STATE) =>
    state.merge({ edit: INITIAL_STATE.edit }, { deep: true }),
});
