import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
const INITIAL_STATE = Immutable({
  workers: [],
  address: {},
  edit: {
    name: "",
    email: "",
    phone: "",

    services: [],
    address: {
      street: "",
      number: "",
      district: "",
      complement: "",
      uf: "",
      city: "",
    },
  },
  new: {
    name: "",
    email: "",
    phone: "",
    services: [],
    address: {
      street: "",
      number: "",
      district: "",
      complement: "",
      uf: "",
      city: "",
    },
  },
  pages: 1,
  page: 1,
});

export const { Types, Creators: WorkerActions } = createActions({
  updateWorkers: ["workers", "pages"],
  updateWorkerPage: ["page"],
  updateWorkerEdit: ["worker"],
  updateWorkerNew: ["worker"],
  resetWorkerNew: [""],
  resetWorkerEdit: [""],
});

const updateWorkers = (state = INITIAL_STATE, { workers, pages }) =>
  state.merge({ workers, pages }, { deep: true });
const updatePage = (state = INITIAL_STATE, { page }) =>
  state.merge({ page }, { deep: true });
const updateEdit = (state = INITIAL_STATE, { worker }) =>
  state.merge({ edit: worker }, { deep: true });
const updateNew = (state = INITIAL_STATE, { worker }) =>
  state.merge({ new: worker }, { deep: true });

export default createReducer(INITIAL_STATE, {
  [Types.UPDATE_WORKERS]: updateWorkers,
  [Types.UPDATE_WORKER_PAGE]: updatePage,
  [Types.UPDATE_WORKER_EDIT]: updateEdit,
  [Types.UPDATE_WORKER_NEW]: updateNew,
  [Types.RESET_WORKER_NEW]: (state = INITIAL_STATE) =>
    state.merge({ new: INITIAL_STATE.new }, { deep: true }),
  [Types.RESET_WORKER_EDIT]: (state = INITIAL_STATE) =>
    state.merge({ edit: INITIAL_STATE.edit }, { deep: true }),
});
