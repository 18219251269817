import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components";

import Icon from "../Icons/Icon";
import ItemNotification from "./ItemNotification";

const NotificationContainer = styled.div`
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  right: -8px;
  top: -15px;
  padding: 5px;
  border-radius: 10px;
  z-index: 5;

  > .notification-title {
    width: 100%;
    display: flex;
    align-items: center;

    &.icon {
      justify-content: flex-end;
    }

    .point {
      background-color: ${props => props.theme.colors.fontPrimary};
      position: absolute;
      top: 6px;
      right: 16px;
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }
  }

  &:hover {
    > .notification-title.icon {
      padding-bottom: 16px;

      .point {
        display: none;
      }
    }

    .content {
      display: block;
      box-shadow: 0px 8px 16px 0px ${props => props.theme.colors.black02};
      background-color: ${props => props.theme.colors.white};

      .notification-title {
        h3 {
          display: block;
        }

        .point {
          display: none;
        }
      }

      .notification-content {
        display: block;
        max-height: 50vh;
        overflow-y: auto;
      }
    }
  }

  .content {
    display: none;

    .notification-title {
      width: 100%;
      display: flex;
      align-items: center;

      &.notification {
        justify-content: flex-start;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      h3 {
        font-size: 0.9em;
        color: ${props => props.theme.colors.fontPrimary};
        display: none;
        margin-left: 5px;
      }

      .point {
        background-color: ${props => props.theme.colors.fontPrimary};
        position: absolute;
        top: 6px;
        right: 16px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
      }
    }

    .notification-content {
      padding: 5px;
      width: 100%;
      display: none;
      width: max-content;
    }
  }
`;

const Content = styled.div``;

function Notification({ theme }) {
  const [showBell, setShowBell] = useState(getShowBellOnLocalStorage());
  const [refreshChild, setRefreshChild] = useState(false);

  function readsNotifications() {
    const schedulesLS = JSON.parse(window.localStorage.getItem("schedules"));

    if (schedulesLS) {
      schedulesLS.forEach(el => (el.read = true));

      window.localStorage.setItem(
        "schedules",
        JSON.stringify(schedulesLS.slice(0, 10))
      );

      setRefreshChild(true);
    }
  }

  function getShowBellOnLocalStorage() {
    return window.localStorage.getItem("showBell");
  }

  function setShowBellOnLocalStorage(showBellLS) {
    window.localStorage.setItem("showBell", showBellLS);

    setRefreshChild(false);

    return getShowBellOnLocalStorage();
  }

  useEffect(() => {
    setShowBell(getShowBellOnLocalStorage());
    return () => {};
  }, []);

  return (
    <NotificationContainer>
      <IconContainer
        onMouseEnter={() =>
          setTimeout(function() {
            readsNotifications();
            setShowBell(setShowBellOnLocalStorage("none"));
          }, 2500)
        }
      >
        <section className="notification-title icon">
          <Icon name="bell" color={theme.colors.fontPrimary} />
          <div className="point" style={{ display: showBell }}></div>
        </section>

        <Content className="content">
          <div className="notification-title notification">
            <h3>Notificações</h3>
          </div>

          <section className="notification-content">
            <ItemNotification
              refresh={refreshChild}
              setShowBell={setShowBell}
            />
          </section>
        </Content>
      </IconContainer>
    </NotificationContainer>
  );
}

export default withTheme(Notification);
