import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Column } from "./StyledItems";
import { IMaskInput } from "react-imask";
import { Input, useField } from "@rocketseat/unform";
import CurrencyInput from "react-currency-input";

export const FormContainer = styled.form`
  width: 25em;
  margin-top: 30px;
`;

export const FormItem = styled(Column)`
  display: flex;
  align-items: flex-start;
  margin-top: 3%;
  width: 100%;
  &:nth-child(1) {
    margin-top: 8%;
  }
`;

export const FormLabel = styled.label`
  font-size: 0.9em;
  color: ${props => props.theme.colors.fontPrimary};
  margin-top: ${({ marginTop }) => marginTop};
  margin-left: ${({ marginLeft }) => marginLeft};
  ${({ between }) =>
    between &&
    `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const FormInput = styled.input`
  width: 100%;
  border: 1px solid ${props => props.theme.colors.fontPrimary};
  border-radius: 3px;
  background: transparent;
  font-size: 0.9em;
  padding: 1.3% 1%;
  color: ${props => props.theme.colors.fontPrimary};
`;
export const FormTextarea = styled.textarea`
  width: 100%;
  border: 1px solid ${props => props.theme.colors.fontPrimary};
  border-radius: 3px;
  background: transparent;
  font-size: 0.9em;
  padding: 1.3% 1%;
  color: ${props => props.theme.colors.fontPrimary};
  resize: none;
  height: 8em;
`;

export const FormButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ align = "flex-end" }) => align};
  margin-top: 3%;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: .6;
    filter: grayscale(1);
    pointer-events: none;
  `}
  ${({ width }) =>
    width &&
    `
    width: ${width}
  `}
`;

export const FormButton = styled.button`
  padding: 2% 3%;
  font-weight: bold;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 3px;
  background: ${props => props.theme.colors.primary};
  color: white;
`;

export const ModalColumn = styled(Column)`
  width: 100%;
  display: block;
  margin-top: 15px;
  /* height: 60px; */
  span.have-error {
    display: block;
  }
`;

export const ModalInput = styled(Input)`
  width: ${({ width = "100%" }) => width};
  border: 1px solid ${props => props.theme.colors.fontPrimary};
  padding: 0.5em 0.1em;
  font-size: 0.9em;
  color: ${props => props.theme.colors.fontPrimary};
  width: 100%;

  ${({ center }) =>
    center &&
    `
    text-align: center;
  `}

  ${({ small }) =>
    small &&
    `
    max-width: 120px;
    border-color: ${props => props.theme.colors.fontPrimary03};
  `}

  &:disabled {
    opacity: 0.4;
    background-color: ${props => props.theme.colors.fontPrimary01};
  }
`;

export const NormalInput = styled.input`
  width: ${({ width = "100%" }) => width};
  border: 1px solid ${props => props.theme.colors.fontPrimary};
  padding: 0.5em 0.1em;
  font-size: 0.9em;
  color: ${props => props.theme.colors.fontPrimary};
  width: 100%;

  ${({ center }) =>
    center &&
    `
    text-align: center;
  `}

  ${({ small }) =>
    small &&
    `
    max-width: 120px;
    border-color: ${props => props.theme.colors.fontPrimary03};
  `}

  &:disabled {
    opacity: 0.4;
    background-color: ${props => props.theme.colors.fontPrimary01};
  }
`;
export const MaskInput = styled(IMaskInput)`
  width: ${({ width = "100%" }) => width};
  border: 1px solid ${props => props.theme.colors.fontPrimary};
  padding: 0.5em 0.1em;
  font-size: 0.9em;
  color: ${props => props.theme.colors.fontPrimary};
  width: 100%;

  ${({ center }) =>
    center &&
    `
    text-align: center;
  `}

  ${({ small }) =>
    small &&
    `
    max-width: 120px;
    border-color: ${props => props.theme.colors.fontPrimary03};
  `}

  &:disabled {
    opacity: 0.4;
    background-color: ${props => props.theme.colors.fontPrimary01};
  }
`;
const StyledMaskCurrenctInput = styled(CurrencyInput)`
  width: ${({ width = "100%" }) => width};
  border: 1px solid ${props => props.theme.colors.fontPrimary};
  padding: 0.5em 0.1em;
  font-size: 0.9em;
  color: ${props => props.theme.colors.fontPrimary};
  width: 100%;

  ${({ center }) =>
    center &&
    `
    text-align: center;
  `}

  ${({ small }) =>
    small &&
    `
    max-width: 120px;
    border-color: ${props => props.theme.colors.fontPrimary03};
  `}

  &:disabled {
    opacity: 0.4;
    background-color: ${props => props.theme.colors.fontPrimary01};
  }
  ${({ error }) =>
    error &&
    `
    border-color: ${props => props.theme.colors.error};
    color: ${props => props.theme.colors.error};
  `}
`;
export function MaskedInput({
  name,
  inputMask,
  unmask,
  defaultValue,
  ...props
}) {
  const ref = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  const [mask, setMask] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: "props.unmaskedvalue",
    });
  }, [ref.current, fieldName]);

  useEffect(() => {
    setMask(defaultValue);
    setTimeout(() => {
      if (ref.current && ref.current.element.getAttribute("typedValue")) {
        if (ref.current.element.getAttribute("typedValue") !== "null") {
          setMask(ref.current.element.getAttribute("typedValue"));
          ref.current.element.removeAttribute("typedValue");
        }
      }
    }, 200);
    return () => {};
  }, [defaultValue]);

  function handleMask(value) {
    return setMask(value);
  }
  return (
    <>
      <MaskInput
        name={fieldName}
        mask={inputMask}
        unmaskedvalue={mask && mask.replace(/[^0-9a-zA-Z]+/g, "")}
        unmask={unmask}
        onAccept={maskValue => handleMask(maskValue)}
        ref={ref}
        value={mask}
        {...props}
      />
      {error && <span>{error}</span>}
    </>
  );
}

export function MaskedCurrencyInput({
  name,
  inputMask,
  unmask,
  defaultValue,
  ...props
}) {
  const ref = useRef(null);
  const { fieldName, registerField, error } = useField(name);
  const [mask, setMask] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: "props.unmaskedvalue",
    });
  }, [ref.current, fieldName]);

  useEffect(() => {
    setMask(defaultValue);
    return () => {};
  }, [defaultValue]);

  function handleMask(value) {
    return setMask(value);
  }
  return (
    <>
      <StyledMaskCurrenctInput
        name={fieldName}
        unmaskedvalue={mask}
        onChangeEvent={(event, mask, unmasked) => handleMask(unmasked)}
        data-mask={true}
        ref={ref}
        value={mask}
        inputType="text"
        {...props}
      />
      {error && <span>{error}</span>}
    </>
  );
}

export const ColumnName = styled.p`
  color: ${props => props.theme.colors.primary};
  font-size: 0.9em;
  margin-top: 25px;

  &:nth-child(2) {
    margin-top: 30px;
  }
`;

export const ButtonSave = styled.button`
  padding: 0.5em 1.2em;
  color: ${props => props.theme.colors.fontTertiary};
  font-size: 0.8em;
  background: ${props => props.theme.colors.primary};
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.primary};
  &:not(:last-child) {
    margin-right: 5px;
  }
  &:hover {
    background: white;
    color: ${props => props.theme.colors.primary};
    transition: all 0.25s ease;
  }
  min-width: ${({ minWidth }) => minWidth};

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: .4;
  `}
`;
