import React from "react";
import styled from "styled-components";
import User from "../Theme/User";

const PhotoContainer = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50px;
  background: ${props => props.theme.colors.gray};
  margin: 0 1em;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function showUserPhoto(photo, scale) {
  if (photo) {
    return <p>img</p>;
  } else {
    return <User scale={scale} />;
  }
}

export default function UserPhoto({ photo, scale }) {
  return <PhotoContainer>{showUserPhoto(photo, scale)}</PhotoContainer>;
}
