import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { CONFIG_API_URL } from "./config/consts";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import themeConfig, { setThemeConfig } from "./config/theme";
import colors from "./components/Theme/colors";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
        -webkit-font-smoothing: antialiased;
    }

    a {
        text-decoration: none;
        color: ${props => props.theme.colors.primary};
        cursor: pointer;
    }

    button {
        border: none;
        cursor: pointer;
        outline: none;
    }

    input {
        text-indent: 5px;
        outline: none;
        border: none;
        :invalid:not(:placeholder-shown):not(:focus):not([type='file']):not([type='time']) {
          border-color: red;
        }
    }
    label {
      &[required] {
          &:after {
            content: ' *'
          }
        }
    }
    .disabled {
      opacity: .4;
      pointer-events: none;
      filter: grayscale(80%);
    }
    tr.disabled {
      text-decoration: line-through
    }

    #root ~ div {
      z-index: 75;
    }
    .draggable-item {
      width: 100%;
      display: flex;
      padding: 10px 0px;
      align-items: center;
      > * {
        margin-right: 5px;
      }
    }

    .slide-down-enter {
      opacity: 0;
      max-height: 0;
    }

    .slide-down-enter-active {
      opacity: 1;
      overflow: hidden;
      max-height: 300px;
      transition: opacity 500ms, max-height 500ms;
    }

    .slide-down-exit {
      opacity: 1;
      max-height: 0;
    }

    .slide-down-exit-active {
      opacity: 0;
      max-height: 0;
      transition: opacity 500ms, max-height 500ms;
    }


    .fade-enter {
      opacity: 0;
      max-height: 0;
    }

    .fade-enter-active {
      opacity: 1;
      overflow: hidden;
      transition: opacity 700ms;
    }

    .fade-exit {
      opacity: 1;
    }

    .fade-exit-active {
      opacity: 0;
      transition: opacity 700ms;
    }
    .have-error {
      border-color: ${props => props.theme.colors.borderError}!important;
      color: ${props => props.theme.colors.error}!important;
    }
    span.have-error {
      font-size: 0.7em;
      text-align: right;
      height:0;
    }
`;
// Get theme from api before render app
axios
  .get(CONFIG_API_URL)
  .then(response => {
    setThemeConfig(response.data);
    Object.keys(response.data).forEach(key => {
      try {
        localStorage.removeItem("config_" + key, response.data[key]);
      } catch (error) {
        console.error(error);
      }
      localStorage.setItem("config_" + key, response.data[key]);
    });
  })
  .catch(error => {
    console.error(error);
    const localConfig = {};
    try {
      Object.keys(themeConfig()).forEach(key => {
        localConfig[key] = localStorage.getItem("config_" + key);
      });
      setThemeConfig(localConfig);
    } catch (error) {
      console.error(error);
    }
  })
  .finally(() => {
    ReactDOM.render(
      <>
        <ThemeProvider theme={{ colors: colors() }}>
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </>,
      document.getElementById("root")
    );

    serviceWorker.register({
      onSuccess: console.log,
      onUpdate: console.table,
    });
  });
