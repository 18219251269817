import React, { useState } from "react";
import styled, { withTheme } from "styled-components";
import Icon from "../Icons/Icon";

const Container = styled.div`
  position: relative;
  .wrapper {
    position: absolute;
    display: inline-block;
  }
  ${props =>
    props.showOnHover &&
    `
  .wrapper {
    display: none;
  }
  &:hover {
    .wrapper {
      display: flex;
      flex-direction: column;
      padding-top: 35px;
      top: 0;
      right: 0px;
      background: transparent;
    }
  }
  `}
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  top: 28px;
  right: -60px;
  width: 163px;
  background-color: white;
  font-weight: bold;
  box-shadow: 0px 8px 16px 0px ${props => props.theme.colors.black02};
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  font-size: 0.9em;
  padding: 8px 16px;
  border-bottom: 1px solid ${props => props.theme.colors.fontTertiary};
  color: ${props => props.theme.colors.fontPrimary};
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: ${props => props.theme.colors.fontTertiary};
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
  }
`;

function StyledDropdownMenu({ theme, items, children, ...props }) {
  const [isShowingMenu, setIsShowingMenu] = useState(false);

  return (
    <Container
      showOnHover={props.showOnHover}
      onClick={() => !props.showOnHover && setIsShowingMenu(!isShowingMenu)}
    >
      {children}
      {(isShowingMenu && !props.showOnHover) ||
        (props.showOnHover && (
          <div className={"wrapper"}>
            <List>
              {items.map((item, i) => (
                <Item key={i} onClick={() => item.onClick()}>
                  <Icon
                    name="key"
                    size={"small"}
                    color={theme.colors.fontPrimary}
                    style={{
                      marginRight: "10px",
                      marginTop: "2px",
                      height: 17,
                      width: 17,
                    }}
                  />
                  {item.text}
                </Item>
              ))}
            </List>
          </div>
        ))}
    </Container>
  );
}

export default withTheme(StyledDropdownMenu);
