import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import { useQuery, useSubscription } from "react-apollo-hooks";
import { NEW_SCHEDULE_SUBSCRIPTION } from "../../graphql/subscription";
import { GET_CALENDAR_2_SCREEN } from "../../graphql/queries";

const NotificationContainer = styled.div``;

const NotificationTitle = styled.div`
  border-top: 1px solid ${props => props.theme.colors.fontPrimary03};
  margin-top: 12px;
  padding-top: 10px;
  margin-bottom: 10px;

  h5 {
    color: ${props => props.theme.colors.fontPrimary};
    font-size: 0.85em;
  }
`;

const NotificationContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${props => props.theme.colors.fontPrimary};
  width: 100%;
  font-size: 0.8em;

  section div {
    p:first-child {
      font-weight: bold;
    }
  }

  section:first-child {
    margin-right: 15px;

    .point {
      background-color: ${props => props.theme.colors.fontPrimary};
      width: 8px;
      height: 8px;
      border-radius: 4px;
    }
  }

  section:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const LeftContainer = styled.div`
  text-align: left;
  margin-right: 25px;

  p:first-child {
    color: ${props => props.theme.colors.primary};
  }
`;

const RightContainer = styled.div`
  text-align: right;
  width: 100%;
`;

const LimitedText = styled.p`
  width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default function ItemNotification({ setShowBell, refresh }) {
  const [schedules, setSchedules] = useState("");

  const { updateQuery } = useQuery(GET_CALENDAR_2_SCREEN, {
    variables: {
      day: dayjs().format("YYYY-MM-DD"),
    },
    fetchPolicy: "network-only",
  });

  function setSchedulesOnLocalStorage(schedulesLS) {
    let schedulesApp = [];

    schedulesApp = schedulesLS.schedules.filter(schedule => schedule.app);
    schedulesApp.sort((itemA, itemB) => itemB.id - itemA.id);

    window.localStorage.setItem(
      "schedules",
      JSON.stringify(schedulesApp.slice(0, 10))
    );

    getSchedulesOnLocalStorage();
  }

  function getSchedulesOnLocalStorage() {
    const schedulesLS = JSON.parse(window.localStorage.getItem("schedules"));

    if (schedulesLS) {
      setSchedules(schedulesLS);
    }

    return schedulesLS;
  }

  useEffect(() => {
    getSchedulesOnLocalStorage();
    return () => {};
  }, [refresh]);

  useSubscription(NEW_SCHEDULE_SUBSCRIPTION, {
    onSubscriptionData({ subscriptionData }) {
      updateQuery(prev => {
        if (!subscriptionData.data) return prev;
        const newSchedule = subscriptionData.data.newSchedule;

        const schedulesLS = getSchedulesOnLocalStorage();

        newSchedule.title = "Novo Agendamento";

        if (schedulesLS) {
          setSchedulesOnLocalStorage(
            Object.assign({}, schedulesLS, {
              schedules: [newSchedule, ...schedulesLS],
            })
          );
        } else {
          let newSchedules = [];
          newSchedules.push(newSchedule);
          window.localStorage.setItem(
            "schedules",
            JSON.stringify(newSchedules)
          );
        }

        if (newSchedule.app) {
          setShowBell("block");
          window.localStorage.setItem("showBell", "block");
        }
      });
    },
  });

  return (
    <>
      {schedules ? (
        schedules.map(schedule => {
          return (
            <NotificationContainer key={schedule.id}>
              <NotificationTitle>
                <h5>{schedule.title}</h5>
              </NotificationTitle>
              <NotificationContent>
                {!schedule.read && (
                  <section style={{ display: "block" }}>
                    <div className="point"></div>
                  </section>
                )}
                <section>
                  <LeftContainer>
                    <LimitedText>{schedule.customer.name}</LimitedText>
                    <LimitedText>
                      {dayjs(new Date(schedule.schedule_to)).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </LimitedText>
                  </LeftContainer>
                  <RightContainer>
                    <LimitedText>{schedule.professional.name}</LimitedText>
                    <LimitedText>{schedule.service.title}</LimitedText>
                  </RightContainer>
                </section>
              </NotificationContent>
            </NotificationContainer>
          );
        })
      ) : (
        <NotificationTitle>
          <h5>Não há notificações!</h5>
        </NotificationTitle>
      )}
    </>
  );
}
