import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

const INITIAL_STATE = Immutable({
  workJourneys: [],
  edit: {
    date: "",
    morningStartAt: "",
    morningEndAt: "",
    eveningStartAt: "",
    eveningEndAt: "",
    professional: {
      id: "",
      name: "",
    },
    services: [],
  },
  new: {
    date: "",
    morningStartAt: "",
    morningEndAt: "",
    eveningStartAt: "",
    eveningEndAt: "",
    professional: {
      id: "",
      name: "",
    },
    services: [],
  },
});

export const { Types, Creators: WorkJourneyActions } = createActions({
  updateWorkJourneys: ["workJourneys"],
  updateEdit: ["workJourney"],
  updateNew: ["WorkJourney"],
  resetNew: [""],
  resetEdit: [""],
});

const updateWorkJourneys = (state = INITIAL_STATE, { workJourneys }) =>
  state.merge({ workJourneys }, { deep: true });
const updateEdit = (state = INITIAL_STATE, { workJourney }) =>
  state.merge({ workJourney }, { deep: true });
const updateNew = (state = INITIAL_STATE, { workJourney }) =>
  state.merge({ workJourney }, { deep: true });

export default createReducer(INITIAL_STATE, {
  [Types.UPDATE_WORK_JOURNEYS]: updateWorkJourneys,
  [Types.UPDATE_EDIT]: updateEdit,
  [Types.UPDATE_NEW]: updateNew,
  [Types.RESET_NEW]: (state = INITIAL_STATE) =>
    state.merge({ new: INITIAL_STATE.new }, { deep: true }),
  [Types.RESET_EDIT]: (state = INITIAL_STATE) =>
    state.merge({ edit: INITIAL_STATE.edit }, { deep: true }),
});
